<template>
  <NuxtLayout>
    <NuxtPage :keepalive="false" :transition="pageTransition" />
  </NuxtLayout>
</template>

<script setup>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { useMenuStore } from "@/stores/menuStore.js";
import { useTransitionStore } from "@/stores/transition.js";

const menuStore = useMenuStore();
const { isCollectionOpen, isServicesOpen } = storeToRefs(menuStore);

const transitionStore = useTransitionStore();

const { $lenis } = useNuxtApp();

const pageTransition = {
  onBeforeEnter: () => {},
  onEnter: (el, done) => {
    menuStore.reset();
    transitionStore.setTransitionOver(true);

    menuStore.setDirection(-1);

    gsap.fromTo(
      el,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: "power2.inOut",
        duration: 0.6,
        onComplete: () => {
          done();
          ScrollTrigger.refresh(true);
        },
      }
    );
  },
  onLeave: (el, done) => {
    if (isCollectionOpen.value) {
      menuStore.closeCollection();
    }
    if (isServicesOpen.value) {
      menuStore.closeServices();
    }

    gsap.fromTo(
      el,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        ease: "power2.inOut",
        duration: 0.6,

        onComplete: () => {
          $lenis.scrollTo(0, {
            immediate: true,
          });
          done();
        },
      }
    );
  },

  mode: "out-in",
};

const { client } = usePrismic();
const { data: meta } = await useAsyncData("meta", () =>
  client.getSingle("meta")
);

useHead({
  title: meta?.value?.data?.title,
  htmlAttrs: { lang: "en" },
  meta: [
    {
      name: "theme-color",
      content: "#000",
    },
    {
      name: "description",
      content: meta?.value?.data?.description,
    },
    {
      hid: "og-title",
      property: "og:title",
      content: meta?.value?.data?.title,
    },
    {
      hid: "og-type",
      property: "og:type",
      content: "website",
    },

    {
      hid: "og-desc",
      property: "og:description",
      content: meta?.value?.data?.description,
    },
    {
      hid: "og-image",
      property: "og:image",
      content: meta?.value?.data?.og_image.url,
    },
    {
      hid: "twitter-title",
      property: "twitter:title",
      content: meta?.value?.data?.title,
    },
    {
      hid: "twitter-desc",
      property: "twitter:description",
      content: meta?.value?.data?.description,
    },
    {
      hid: "twitter-image",
      property: "twitter:image",
      content: meta?.value?.data?.og_image.url,
    },
    {
      hid: "twitter-card",
      property: "twitter:card",
      content: "summary_large_image",
    },
  ],
  link: [
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/apple-touch-icon.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "96x96",
      href: "/favicon-96x96.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "192x192",
      href: "/favicon-192x192.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "512x512",
      href: "/favicon-512x512.png",
    },
    {
      rel: "stylesheet",
      href: "https://use.typekit.net/clv7tvj.css",
    },
  ],
  script: [
    {
      src: "https://static.cdn.prismic.io/prismic.js?new=true&repo=duchateau",
      async: true,
      defer: true,
    },
    {
      textContent: `window._mfq = window._mfq || [];
        (function() {
          var mf = document.createElement("script");
          mf.type = "text/javascript"; mf.defer = true;
          mf.src = "//cdn.mouseflow.com/projects/3f3ce5bf-b226-4ae4-9f8c-57f8785db490.js";
          document.getElementsByTagName("head")[0].appendChild(mf);
        })();`,
    },
    // {
    //   textContent: `
    //     window.markerConfig = {
    //       project: '671ae0d77a3b33b4b915c1f0',
    //       source: 'snippet'
    //     };

    //     !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","clearReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
    //   `,
    //   tagPosition: "bodyClose",
    // },
  ],
});
</script>
