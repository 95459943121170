import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "7.036",
      cy: "7.536",
      r: "5.571",
      stroke: "#000",
      "stroke-width": ".929"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#000",
      "stroke-width": ".929",
      d: "M11.215 11.714 14 14.5"
    }, null, -1)
  ])))
}
export default { render: render }