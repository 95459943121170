<template>
  <div>
    <div ref="overlay" class="overlay" />
    <div ref="el" class="collection-menu" @mouseleave="closeCollectionMenu">
      <div class="collections-container">
        <div
          v-for="item in collection.data.collections"
          :key="item.collection_name"
          ref="collectionItem"
          class="collection-item"
        >
          <NuxtLink :to="item.link" @click="closeCollectionMenuFromClick">
            <div>
              <div class="image-container">
                <div ref="image" class="image">
                  <CustomImage :image="item.image" :sizes="[330, 330]" />
                </div>
              </div>
            </div>
          </NuxtLink>
          <div class="content">
            <PrismicRichText ref="copy" class="copy" :field="item.content" />
          </div>
        </div>
      </div>

      <PrismicRichText
        class="extra-infos"
        :field="collection.data.extra_infos"
      />
    </div>
  </div>
</template>

<script setup>
import { SplitText } from "gsap/SplitText";
import { useMenuStore } from "@/stores/menuStore.js";
import { gsap } from "gsap";
import { useTransitionStore } from "@/stores/transition.js";

const transitionStore = useTransitionStore();
const menuStore = useMenuStore();
const { isCollectionOpen, isServicesOpen } = storeToRefs(menuStore);

const el = shallowRef(null);
const copy = ref([]);
const image = ref([]);
const overlay = shallowRef(null);
const collectionItem = ref([]);

const { $lenis } = useNuxtApp();

const { client } = usePrismic();
const { data: collection } = await useAsyncData("collection_menu", () =>
  client.getSingle("collection_menu")
);

watch(isCollectionOpen, (newVal) => {
  if (newVal === true) {
    openMenu();
    if (isServicesOpen) {
      menuStore.closeServices();
    }
  } else {
    if (isCollectionOpen) {
      closeCollectionMenu();
    }
  }
});

let ctx;
// const allMenuWords = [];
let openMenuTL;

onMounted(() => {
  // splitTexts();

  gsap.set(el.value, {
    yPercent: -105,
  });
});

// const splitTexts = () => {
//   copy.value.forEach((element) => {
//     const target = element.$el.querySelector("p");

//     const newWord = new SplitText(target, {
//       type: "lines",
//       linesClass: "--word",
//     });

//     gsap.set(element.$el, {
//       autoAlpha: 1,
//     });

//     gsap.set(newWord.elements, {
//       opacity: 0,
//     });

//     allMenuWords.push(...newWord.elements);
//   });
// };

const openMenu = () => {
  $lenis.stop();
  ctx = gsap.context(() => {
    openMenuTL = gsap.timeline();

    collectionItem.value.forEach((element, index) => {
      gsap.set(element, {
        y: 0,
        overwrite: "auto",
      });

      gsap.to(element, {
        opacity: 1,
        duration: 1,
        ease: "expo.out",
        delay: index * 0.06,
      });
    });

    gsap.to(overlay.value, {
      opacity: 1,
      duration: 1,
      ease: "expo.out",
    });

    openMenuTL.set(el.value, {
      autoAlpha: 1,
      pointerEvents: "all",
    });

    if (menuStore.isCollectionOpen === true) {
      gsap.to(el.value, {
        yPercent: 0,
        duration: 1,
        ease: "expo.out",
      });
    }

    // if (allMenuWords) {
    //   openMenuTL.fromTo(
    //     allMenuWords,
    //     {
    //       yPercent: 30,
    //       opacity: 0,
    //     },
    //     {
    //       yPercent: 0,
    //       autoAlpha: 1,
    //       duration: 0.8,
    //       ease: "expo.out",
    //       stagger: 0.05,
    //     },
    //     0.3
    //   );
    // }

    image.value.forEach((element, index) => {
      openMenuTL.fromTo(
        element,
        {
          yPercent: 30,
        },
        {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1,
          ease: "expo.out",
          delay: index * 0.06,
        },
        0
      );
    });
  });
};

const closeCollectionMenu = (e) => {
  if (transitionStore.getTransitionOver === false) return;

  $lenis.start();
  ctx.add(() => {
    gsap.set(el.value, {
      pointerEvents: "none",
    });
    gsap.to(overlay.value, {
      opacity: 0,
      duration: 1,
      ease: "power2.inOut",
    });
    gsap.to(el.value, {
      yPercent: -105,
      duration: 1,
      ease: "power2.inOut",
    });

    // gsap.to(allMenuWords, {
    //   yPercent: -30,
    //   opacity: 0,
    //   duration: 0.8,
    //   ease: "power3.out",
    //   stagger: 0.05,
    // });

    collectionItem.value.forEach((element, index) => {
      gsap.to(element, {
        y: el.value.offsetHeight * 0.8,
        duration: 1,
        ease: "power2.inOut",
      });

      gsap.to(element, {
        opacity: 0,
        duration: 0.7,
        ease: "power2.inOut",
        delay: index * 0.01,
      });
    });
  });

  if (isCollectionOpen) {
    menuStore.closeCollection();
  }
};

const closeCollectionMenuFromClick = () => {
  closeCollectionMenu();
  transitionStore.setTransitionOver(false);
};
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.6);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
}

.collection-menu {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  background-color: $white;
  height: fit-content;

  left: 0;

  padding: 9rem $padding-desktop 0;

  visibility: hidden;
  pointer-events: none;
  will-change: transform;

  overflow: hidden;
}

.collections-container {
  @include grid-desktop;
  width: 100%;
}

.collection-item {
  grid-column: span 3;
  margin-bottom: 3rem;
  overflow: hidden;
  height: max-content;
}

.collection-item:deep(img) {
  will-change: transform;
  transition: transform 0.5s ease;
}

.collection-item:hover :deep(img) {
  transform: scale(1.05);
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
  visibility: hidden;
  aspect-ratio: 330 / 210;
  will-change: transform;
}

.content {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding-top: 0.8rem;
}

.copy {
  color: #2d2b23;

  font-family: $beaufort-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  overflow: hidden;
  :deep(strong) {
    display: inline-block;
    color: #000;
    font-family: $suisse-semibold;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 133.333% */
    letter-spacing: -0.12px;
    transform: translateY(-4px);
    margin-right: 4px;
    margin-bottom: -5px;
  }
  :deep(a) {
    @include link-active;
  }
  :deep(p) {
  }
  :deep(.--word) {
    display: flex;
    align-items: normal;
  }

  :deep(a:has(> strong)) {
    &::before,
    &::after {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
  }
}

.extra-infos {
  @include caption-small;
  padding-bottom: 0.5rem;
  color: rgba($color: #000000, $alpha: 0.5);
  :deep(strong) {
    @include body-small;
    color: $black;
  }
}
</style>
