<template>
  <div>
    <div ref="overlay" class="overlay" />
    <div ref="el" class="collection-menu" @mouseleave="closeServicesMenu">
      <div class="collections-container">
        <div
          v-for="(item, index) in collection.data.services"
          :key="index"
          ref="collectionItem"
          class="collection-item"
        >
          <NuxtLink :to="item.link" @click="closeServicesMenuFromClick">
            <div>
              <div class="image-container">
                <div ref="image" class="image">
                  <CustomImage :image="item.image" :sizes="[330, 330]" />
                </div>
              </div>

              <div class="content">
                <PrismicRichText ref="copy" class="copy" :field="item.copy" />
              </div>
            </div>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { SplitText } from "gsap/SplitText";
import { useMenuStore } from "@/stores/menuStore.js";
import { gsap } from "gsap";
import { useTransitionStore } from "@/stores/transition.js";

const transitionStore = useTransitionStore();

const menuStore = useMenuStore();
const { isServicesOpen, isCollectionOpen } = storeToRefs(menuStore);

const el = shallowRef(null);
const copy = ref([]);
const image = ref([]);
const overlay = shallowRef(null);

const collectionItem = ref([]);

const { $lenis } = useNuxtApp();

const { client } = usePrismic();
const { data: collection } = await useAsyncData("servicesmenu", () =>
  client.getSingle("design_services_menu")
);

watch(isServicesOpen, (newVal) => {
  if (newVal === true) {
    openMenu();
    if (isCollectionOpen) {
      menuStore.closeCollection();
    }
  } else {
    if (isServicesOpen) {
      closeServicesMenu();
    }
  }
});

let ctx;
const allMenuWords = [];
let openMenuTL;

onMounted(() => {
  ctx = gsap.context(() => {
    gsap.set(el.value, {
      yPercent: -105,
    });
  });
  splitTexts();
});

const splitTexts = () => {
  copy.value.forEach((element) => {
    const target = element.$el.querySelector("p");

    const newWord = new SplitText(target, {
      type: "words",
      wordsClass: "--word",
    });

    gsap.set(element.$el, {
      autoAlpha: 1,
    });

    gsap.set(newWord.elements, {
      opacity: 0,
    });

    allMenuWords.push(...newWord.elements);
  });
};

const openMenu = () => {
  $lenis.stop();

  ctx = gsap.context(() => {
    openMenuTL = gsap.timeline();

    collectionItem.value.forEach((element, index) => {
      gsap.set(element, {
        y: 0,
        overwrite: "auto",
      });

      gsap.to(element, {
        opacity: 1,
        duration: 1,
        ease: "expo.out",
        delay: index * 0.06,
      });
    });

    gsap.to(overlay.value, {
      opacity: 1,
      duration: 1,
      ease: "expo.out",
    });

    openMenuTL.set(el.value, {
      autoAlpha: 1,
      pointerEvents: "all",
    });

    if (menuStore.isServicesOpen === true) {
      gsap.to(el.value, {
        yPercent: 0,
        duration: 1,
        ease: "expo.out",
      });
    }

    if (allMenuWords) {
      openMenuTL.fromTo(
        allMenuWords,
        {
          yPercent: 30,
          opacity: 0,
        },
        {
          yPercent: 0,
          autoAlpha: 1,
          duration: 0.8,
          ease: "expo.out",
          stagger: 0.05,
        },
        0.3
      );
    }

    image.value.forEach((element, index) => {
      openMenuTL.fromTo(
        element,
        {
          yPercent: 30,
        },
        {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1,
          ease: "expo.out",
          delay: index * 0.06,
        },
        0
      );
    });
  });
};

const closeServicesMenu = (e) => {
  $lenis.start();
  if (transitionStore.getTransitionOver === false) return;

  ctx.add(() => {
    gsap.to(overlay.value, {
      opacity: 0,
      duration: 1,
      ease: "power2.inOut",
    });
    gsap.to(el.value, {
      yPercent: -105,
      duration: 1,
      ease: "power2.inOut",
    });

    gsap.to(allMenuWords, {
      yPercent: -30,
      opacity: 0,
      duration: 0.8,
      ease: "power3.out",
      stagger: 0.05,
    });

    collectionItem.value.forEach((element, index) => {
      gsap.to(element, {
        y: el.value.offsetHeight * 0.8,
        duration: 1,
        ease: "power2.inOut",
      });

      gsap.to(element, {
        opacity: 0,
        duration: 0.7,
        ease: "power2.inOut",
        delay: index * 0.01,
      });
    });
  });

  if (isServicesOpen) {
    menuStore.closeServices();
  }
};

const closeServicesMenuFromClick = () => {
  closeServicesMenu();
  transitionStore.setTransitionOver(false);
};
onBeforeUnmount(() => {
  ctx?.kill(false);
});
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.6);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
}

.collection-menu {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  background-color: $white;
  height: fit-content;

  left: 0;

  padding: 9rem $padding-desktop 0;

  visibility: hidden;
  pointer-events: none;
  will-change: transform;
  overflow: hidden;
}

.collections-container {
  @include grid-desktop;
  width: 100%;
}

.collection-item {
  grid-column: span 3;
  margin-bottom: 3rem;
  overflow: hidden;
  height: max-content;
}

.collection-item:deep(img) {
  will-change: transform;
  transition: transform 0.5s ease;
}

.collection-item:hover :deep(img) {
  transform: scale(1.05);
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
  visibility: hidden;
  aspect-ratio: 330 / 210;
  will-change: transform;
}

.content {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding-top: 0.8rem;
}

.copy {
  font-family: $beaufort-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 114.286% */
  display: inline-block;
  overflow: hidden;
  visibility: hidden;
  :deep(strong) {
    font-family: $suisse-semibold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 133.333% */
    letter-spacing: -0.12px;
  }
  :deep(a) {
    @include link-active;
  }
  :deep(.--word) {
    display: inline-block;
  }
}

.extra-infos {
  @include caption-small;
  padding-bottom: 0.5rem;
  color: rgba($color: #000000, $alpha: 0.5);
  :deep(strong) {
    @include body-small;
    color: $black;
  }
}
</style>
