<template>
  <div
    ref="el"
    class="menu-mobile"
    :class="{
      hasBackground:
        (hasBackgroundComputed === true && !isMenuOpen) || isSearchOpen,
      isLight:
        isMenuLight === true &&
        !isMenuOpen &&
        !hasBackgroundComputed &&
        !isSearchOpen,
      isOpen: isMenuOpen,
    }"
  >
    <button
      class="menu-button"
      :class="{ isOpen: isMenuOpen }"
      @click="toggleMenu"
    >
      <div class="menu-button-content">
        <span class="menu-button-menu">Menu</span>
        <span class="menu-button-close">
          <span class="close-icon">
            <CloseIcon class="close-icon-svg" />
          </span>
          <span class="close-text">Close</span>
        </span>
      </div>
    </button>

    <NuxtLink to="/">
      <LogoBlack class="logo" />
    </NuxtLink>

    <div class="icons-container">
      <button class="cart-icon" @click="menuStore.openCart">
        <div v-if="cartCount && cartCount > 0" class="cart-count">
          {{ cartCount }}
        </div>
        <CartIcon class="cart-svg icon-svg" />
      </button>

      <NuxtLink to="/account">
        <UserIcon class="icon-svg" />
      </NuxtLink>
      <button
        class="search-icon"
        @click="
          () =>
            isSearchOpenComputed
              ? menuStore.closeSearch()
              : menuStore.openSearch()
        "
      >
        <SearchIcon class="icon-svg" />
      </button>
    </div>
    <div
      ref="cart"
      class="cart cart-mobile hidden"
      :class="{ isOpen: isCartOpen }"
      @click.self="menuStore.closeCart"
    >
      <Cart :close-cart="menuStore.closeCart" :is-open="showCart" />
    </div>

    <div class="search">
      <Search :close-search="menuStore.closeSearch" />
    </div>
  </div>
</template>

<script setup>
import Cart from "@/components/menu/Cart.vue";
import Search from "@/components/menu/Search.vue";
import { gsap } from "gsap";
import CloseIcon from "@/assets/images/icons/close.svg";
import CartIcon from "@/assets/images/icons/cart-icon.svg?skipsvgo";
import UserIcon from "@/assets/images/icons/user-icon.svg?skipsvgo";
import SearchIcon from "@/assets/images/icons/search-icon.svg?skipsvgo";
import LogoBlack from "@/assets/images/icons/duchateau-logo-black.svg";
// STORES
import { useMenuMobileStore } from "@/stores/menuMobileStore.js";
import { useMenuStore } from "@/stores/menuStore.js";
import { useCustomerStore } from "@/stores/shopify/customers";

import { useSizes } from "@/composables/useSizes";

const { $lenis } = useNuxtApp();

const { navbarHeight } = useSizes();

const customerStore = useCustomerStore();
const menuStore = useMenuStore();
const menuMobileStore = useMenuMobileStore();

const { isCartOpen, isSearchOpen, hasMenuBackground, isLight } =
  storeToRefs(menuStore);

const { cartCount: cartCountStore } = storeToRefs(customerStore);

const { isMenuOpen } = storeToRefs(menuMobileStore);
const isSearchOpenComputed = computed(() => menuStore.isSearchOpen);
const showCart = computed(() => isCartOpen.value);
const cartCount = computed(() => cartCountStore.value);

const el = shallowRef(null);
const cart = shallowRef(null);

const isMenuLight = computed(() => {
  return isLight.value;
});

const hasBackgroundComputed = computed(() => {
  return hasMenuBackground.value;
});

onMounted(() => {
  customerStore.fetchCart();
});

// Watch for changes in isSearchOpen or isCartOpen to lock/unlock scroll using Lenis
watch([isSearchOpen, isCartOpen], ([searchOpen, cartOpen]) => {
  if (searchOpen || cartOpen) {
    $lenis?.stop(); // Lock the scroll
  } else {
    $lenis?.start(); // Unlock the scroll
  }
});

watch(isCartOpen, () => {
  gsap.set(cart.value, {
    autoAlpha: 1,
  });
});

watch(
  () => menuStore.getDirection,
  (direction) => {
    toggleMenuPosition(direction);
  }
);

const toggleMenuPosition = (direction) => {
  if (direction === 1) {
    gsap.to(el.value, {
      y: -navbarHeight.value * 2,
      overwrite: true,
      duration: 0.4,
      ease: "power2.out",
    });
  } else {
    gsap.to(el.value, {
      y: 0,
      overwrite: true,
      duration: 0.4,
      ease: "power2.inOut",
    });
  }
};

const toggleMenu = () => {
  if (isMenuOpen.value) {
    menuMobileStore.close();
  } else {
    menuMobileStore.open();
  }
};
</script>

<style lang="scss" scoped>
.menu-mobile {
  height: 4.8rem;
  width: 100%;
  position: fixed;
  top: 0;
  padding: 1rem var(--padding-mobile);
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $black;
  transition: 0.5s $ease-out-expo;

  a {
    color: $black;
    &::after {
      background-color: $black;
    }
  }
  .link-sample {
    color: $black;
    .dot {
      background-color: $black;
    }
  }
  .logo,
  .logo :deep(path),
  path,
  svg path {
    fill: $black;
  }

  .cart-count {
    width: 14px;
    height: 14px;
    margin: auto;
    background-color: $white;
    color: $black;
    border-radius: 50%;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cart-svg {
    transform: translateY(1.25px) translateX(0.5px);
  }

  .icon-svg,
  .icon-svg:deep(circle),
  .icon-svg:deep(path) {
    stroke: black;
  }

  .link.router-link-exact-active {
    @include link-active-full;
    &::before,
    &::after {
      background-color: black;
      transform: scaleX(1);
    }
  }
}

.menu-button-menu {
  color: $black;
}

.close-icon-svg :deep(path) {
  fill: white;
}

.menu-button {
  background: rgba(255, 255, 255, 0.2);
  position: relative;
  width: 58px;
  height: 28px;
  overflow: hidden;
  @include tablet {
    width: 68px;
    height: 38px;
  }
}

.menu-button:not(.isOpen) {
  .close-text {
    opacity: 0;
  }
}

.menu-button.isOpen {
  .menu-button-content {
    transform: translate(-50%, -80%);
  }
  .menu-button-menu {
    opacity: 0;
  }
}

.menu-button-content {
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: 0.5s $ease-out-expo;
}

.menu-button-menu,
.close-text {
  color: $black;
  font-family: $beaufort-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  transition: 0.5s $ease-out-expo;
  @include tablet {
    font-size: 18px;
    line-height: 18px; /* 100% */
  }
}

.menu-button-close {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  :deep(path) {
    fill: $black;
  }
}

.close-icon {
  height: 10px;
  width: 10px;
}

.logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  height: 1.8rem;
  width: auto;
}

.icons-container {
  height: auto;
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}

.icon-svg {
  width: 18px;
  height: 18px;
  @include tablet {
    width: 22px;
    height: 22px;
  }
}

.menu-mobile.isOpen {
  background-color: transparent;
  transition-delay: 0.3s;

  .menu-button {
    background: rgba(27, 27, 27, 0.06);
  }

  .menu-button-menu,
  .close-text {
    color: $black;
  }

  a {
    color: $black !important;
    &::after {
      background-color: $black !important;
    }
  }
  .link-sample {
    color: $black !important;
    .dot {
      background-color: $black;
    }
  }
  .logo,
  .logo :deep(path),
  path,
  svg path {
    fill: $black !important;
  }

  .icon-svg,
  .icon-svg:deep(circle),
  .icon-svg:deep(path) {
    stroke: black !important;
  }

  .link.router-link-exact-active {
    @include link-active-full;
    &::before,
    &::after {
      background-color: black;
      transform: scaleX(1);
    }
  }
}

.menu-mobile.hasBackground {
  background-color: $white;

  .menu-button {
    background: transparent;
  }

  .menu-button-menu {
    color: $black;
  }

  a {
    color: $black !important;
    &::after {
      background-color: $black !important;
    }
  }
  .link-sample {
    color: $black !important;
    .dot {
      background-color: $black;
    }
  }
  .logo,
  .logo :deep(path),
  path,
  svg path {
    fill: $black !important;
  }

  .icon-svg,
  .icon-svg:deep(circle),
  .icon-svg:deep(path) {
    stroke: black !important;
  }

  .link.router-link-exact-active {
    @include link-active-full;
    &::before,
    &::after {
      background-color: black;
      transform: scaleX(1);
    }
  }
}

.menu-mobile.isLight {
  .menu-button-menu {
    color: $white;
  }

  a {
    color: $white;
    &::after {
      background-color: $white;
    }
  }
  .link-sample {
    color: $white;
    .dot {
      background-color: $white;
    }
  }
  .logo,
  .logo :deep(path),
  path,
  svg path {
    fill: $white;
  }

  .icon-svg,
  .icon-svg:deep(circle),
  .icon-svg:deep(path) {
    stroke: $white;
  }

  .link.router-link-exact-active {
    @include link-active-full;
    &::before,
    &::after {
      background-color: $white;
      transform: scaleX(1);
    }
  }

  .menu-divider {
    background-color: rgba($color: #ffffff, $alpha: 0.5);
  }
}

.search {
  position: absolute;
}

.cart {
  position: fixed;
  inset: 0;
  z-index: 11;
  height: 100vh;
  pointer-events: none;
  background: white;
  transition: 1s $ease-out-expo;

  transform: translateX(100%);
  visibility: hidden;

  &.isOpen {
    pointer-events: all;
    background: rgba(0, 0, 0, 0.5);
    transform: translateX(0%);
  }
  @include desktop {
    cursor: pointer;
  }
}
</style>
