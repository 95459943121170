import { useMobile } from "@/composables/useMobile";

export const useSizes = () => {
  const { isMobile } = useMobile();
  const navbarHeight = ref(0);

  const checkSizes = () => {
    navbarHeight.value = isMobile.value
      ? document.querySelector(".menu-mobile").getBoundingClientRect().height
      : document.querySelector(".menu-desktop").getBoundingClientRect().height;
  };

  onMounted(() => {
    checkSizes();

    window.addEventListener("resize", checkSizes);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", checkSizes);
  });

  return { navbarHeight };
};
