import { defineStore } from "pinia";

export const useTransitionStore = defineStore("transition", {
  state: () => ({
    isTransitionOver: true,
    isSamples: null,
  }),
  actions: {
    setTransitionOver(bool) {
      this.isTransitionOver = bool;
    },
    setIsSamples(bool) {
      this.isSamples = bool;
    },
  },
  getters: {
    getTransitionOver: (state) => state.isTransitionOver,
    getIsSamples: (state) => state.isSamples,
  },
});
