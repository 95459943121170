// plugins/slugify.ts
import slugify from "slugify";

export default defineNuxtPlugin(() => {
  const defaultConfig = {
    replacement: "-",
    remove: undefined,
    lower: true,
    strict: false,
    locale: "fr",
    trim: true,
  };

  return {
    provide: {
      slugify: (str: string, config = {}) =>
        slugify(str, {
          ...defaultConfig,
          ...config,
        }),
    },
  };
});
