import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 18 18",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#fff",
      d: "M3.737 6.7h8.526a1.5 1.5 0 0 1 1.492 1.345l.69 6.655H1.555l.69-6.655A1.5 1.5 0 0 1 3.737 6.7Z"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#fff",
      d: "M11 8V5a3 3 0 0 0-6 0v3"
    }, null, -1)
  ])))
}
export default { render: render }