<template>
  <MenuMobile v-show="isMobile" />
  <MenuMobileOverlay v-show="isMobile" />

  <MenuDesktop v-show="!isMobile" />
  <div class="page-error">
    <div class="content">
      <ErrorIcon class="icon" />
      <Link label="Go back to homepage" link="/" />
    </div>

    <div class="image-container">
      <NuxtImg class="image" src="/images/error.jpg" alt="404 image" />
    </div>
  </div>
</template>

<script setup>
import ErrorIcon from "@/assets/images/icons/error-icon.svg";
import Link from "@/components/Link.vue";

const { isMobile } = useMobile();
</script>

<style lang="scss" scoped>
.page-error {
  height: 100vh;
  position: relative;
  width: 100%;
  padding: 0 var(--padding-mobile);
  overflow: hidden;

  @include desktop {
    padding: 0 $padding-desktop;
    @include grid-desktop;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @include desktop {
    grid-column: 1 / span 6;
  }
}

.icon {
  width: 100%;
  margin-bottom: 4rem;
}

.image-container {
  display: none;
  @include desktop {
    display: block;
    grid-column: 7 / span 6;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.image {
  height: calc(100% - 12.8rem);
}
</style>
