import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from "lenis";

export default defineNuxtPlugin(() => {
  const lenis = ref(null);

  lenis.value = new Lenis({
    lerp: 0.1,
  });

  lenis.value.stop();

  gsap.ticker.add((time) => {
    lenis.value.raf(time * 1000);
  });

  lenis.value.on("scroll", ScrollTrigger.update);

  gsap.ticker.lagSmoothing(0);

  return {
    provide: {
      lenis: lenis.value,
    },
  };
});

export const useLenis = () => {
  return useNuxtApp().$lenis;
};
