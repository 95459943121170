import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

export default defineNuxtPlugin(() => {
  gsap.registerPlugin(ScrollTrigger, SplitText);
  gsap.ticker.lagSmoothing(0);

  ScrollTrigger.config({
    ignoreMobileResize: true,
  });

  window.addEventListener("resize", () => {
    ScrollTrigger.refresh(true);
  });
});
