
import * as prismicRuntime$xtV3hJP73B from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/prismic'
import * as vercelRuntime$b8uWPRx1qM from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 319,
    "sm": 479,
    "md": 767,
    "lg": 1023,
    "xl": 1439,
    "xxl": 1679,
    "2xl": 1536,
    "3xl": 1919,
    "4xl": 2559
  },
  "presets": {},
  "provider": "vercel",
  "domains": [
    "images.prismic.io"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "avif",
    "jpeg",
    "jpg",
    "png",
    "gif",
    "webp"
  ]
}

imageOptions.providers = {
  ['prismic']: { provider: prismicRuntime$xtV3hJP73B, defaults: {} },
  ['vercel']: { provider: vercelRuntime$b8uWPRx1qM, defaults: {} }
}
        