import axios from "axios";
import { defineStore } from "pinia";

const CART_FIELDS = `
id
checkoutUrl
lines(first: 20) {
  edges {
    node {
      id
      quantity
      cost {
        totalAmount {
          amount
          currencyCode
        }
      }
      merchandise {
        ... on ProductVariant {
          id
          title
          selectedOptions {
            name
            value
          }
          product {
            title
            displayTitle: metafield(namespace: "custom", key: "display_title") { value }
            productType
            images(first: 1) {
              edges {
                node {
                  src
                  altText
                }
              }
            }
            collections(first: 1) {
              edges {
                node {
                  id
                  handle
                  title
                }
              }
            }
            priceRange {
              minVariantPrice {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
  }
}
estimatedCost {
  totalAmount {
    amount
    currencyCode
  }
}
`;

export const useCustomerStore = defineStore("customer", {
  state: () => {
    const config = useRuntimeConfig();
    return {
      customerToken:
        typeof window !== "undefined"
          ? localStorage.getItem("dc_customerToken")
          : null,
      cartId:
        typeof window !== "undefined"
          ? localStorage.getItem("dc_cartId")
          : null,
      cart: null, // Store the cart data
      errors: null,
      isLoading: false,
      SHOPIFY_STORE_URL: config.public.SHOPIFY_STORE_URL,
      STOREFRONT_ACCESS_TOKEN: config.public.SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    };
  },

  actions: {
    async fetchCustomer() {
      this.isLoading = true;
      // Ensure this code only runs on the client side
      if (typeof window === "undefined") return null;

      // Check if the token is already set in the store or in localStorage
      if (!this.customerToken) {
        const storedToken = localStorage.getItem("dc_customerToken");
        if (storedToken) {
          this.customerToken = storedToken;
        } else {
          return null;
        }
      }

      try {
        // Fetch the customer data using the customer access token
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          {
            query: `
              query {
                customer(customerAccessToken: "${this.customerToken}") {
                  id
                  firstName
                  lastName
                  email
                  orders(first: 20) {
                    totalCount
                    edges {
                      node {
                        id
                        name
                        billingAddress {
                          firstName
                          lastName
                          address1
                          city
                          province
                          country
                          zip
                          phone
                        }
                        totalPrice {
                          amount
                          currencyCode
                        }
                      }
                    }
                  }
                }
              }
            `,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;

        // If customer data is fetched successfully, return it
        this.isLoading = false;
        if (data?.customer) {
          return data.customer;
        } else {
          // If the token is invalid, log out
          this.logout();
          return null;
        }
      } catch (error) {
        this.isLoading = false;
        console.error("Fetch customer error:", error);
        this.logout(); // Log out if an error occurs
        return null;
      }
    },

    async updateCustomer({ firstName, lastName, email, password }) {
      this.isLoading = true;
      // Check if the customer token is available
      if (!this.customerToken) {
        console.error("No customer token found.");
        return { success: false, error: "No customer token found." };
      }

      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          {
            query: `
              mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
                customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
                  customer {
                    id
                    firstName
                    lastName
                    email
                  }
                  customerAccessToken {
                    accessToken
                    expiresAt
                  }
                  customerUserErrors {
                    code
                    message
                    field
                  }
                }
              }
            `,
            variables: {
              customerAccessToken: this.customerToken,
              customer: {
                firstName,
                lastName,
                email,
                password: password || null, // Optional password update
              },
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;

        this.isLoading = false;
        // Check for successful update
        if (data.customerUpdate?.customer) {
          const newAccessToken =
            data.customerUpdate.customerAccessToken?.accessToken;

          // If a new token is returned, update it
          if (newAccessToken) {
            this.customerToken = newAccessToken;
            localStorage.setItem("dc_customerToken", newAccessToken);
          }

          return { success: true, newAccessToken };
        } else {
          console.error(
            "Customer update errors:",
            data.customerUpdate.customerUserErrors
          );
          return {
            success: false,
            errors: data.customerUpdate.customerUserErrors,
          };
        }
      } catch (error) {
        this.isLoading = false;
        console.error("Update error:", error);
        return {
          success: false,
          error: "An error occurred during the update.",
        };
      }
    },

    async login(email, password) {
      this.isLoading = true;
      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          {
            query: `
              mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
                customerAccessTokenCreate(input: $input) {
                  customerAccessToken {
                    accessToken
                    expiresAt
                  }
                  customerUserErrors {
                    code
                    field
                    message
                  }
                }
              }
            `,
            variables: {
              input: { email, password },
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;
        this.isLoading = false;

        if (data.customerAccessTokenCreate.customerAccessToken) {
          const accessToken =
            data.customerAccessTokenCreate.customerAccessToken.accessToken;
          this.customerToken = accessToken;
          if (typeof window !== "undefined")
            localStorage.setItem("dc_customerToken", accessToken); // Save token to localStorage
          return { success: true, accessToken };
        } else {
          const errors =
            data.customerAccessTokenCreate?.customerUserErrors || data.errors;
          this.errors = errors;
          return { success: false, errors };
        }
      } catch (error) {
        this.isLoading = false;
        console.error("Login error", error);
        this.errors = [{ message: "Something went wrong." }];
        return { success: false, error: "Something went wrong." };
      }
    },

    async signup({ email, password, firstName, lastName }) {
      this.isLoading = true;
      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          {
            query: `
              mutation customerCreate($input: CustomerCreateInput!) {
                customerCreate(input: $input) {
                  customer {
                    id
                    email
                    firstName
                    lastName
                  }
                  customerUserErrors {
                    code
                    field
                    message
                  }
                }
              }
            `,
            variables: { input: { email, password, firstName, lastName } },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;
        this.isLoading = false;

        if (data.customerCreate?.customer) {
          const loginResult = await this.login(email, password);
          return {
            success: true,
            customer: data.customerCreate.customer,
            ...loginResult,
          };
        } else {
          const errors = data.customerCreate?.customerUserErrors || data.errors;
          this.errors = errors;
          return { success: false, errors };
        }
      } catch (error) {
        this.isLoading = false;
        console.error("Signup error", error);
        this.errors = [{ message: "Something went wrong during signup." }];
        return { success: false, error: "Something went wrong during signup." };
      }
    },

    logout() {
      this.customerToken = null;
      this.errors = null;
      if (typeof window !== "undefined")
        localStorage.removeItem("dc_customerToken"); // Remove token from localStorage
    },

    // Cart
    async getVariantIdByHandle(handle) {
      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          {
            query: `
              query getProductByHandle($handle: String!) {
                product(handle: $handle) {
                  variants(first: 1) {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            `,
            variables: { handle },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const variantId =
          response.data?.data?.product?.variants?.edges[0]?.node?.id || null;
        return variantId;
      } catch (error) {
        console.error("Fetch variant ID by handle error:", error);
        return null;
      }
    },

    async createCart(variantId, quantity = 1) {
      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          {
            query: `
              mutation createCart($input: CartInput!) {
                cartCreate(input: $input) {
                  cart { ${CART_FIELDS} }
                }
              }
            `,
            variables: {
              input: {
                lines: [
                  {
                    merchandiseId: variantId,
                    quantity: quantity,
                  },
                ],
              },
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;
        this.cart = data?.cartCreate?.cart || null;

        // Store the cart ID to reuse the cart
        if (this.cart) {
          this.cartId = this.cart.id;
          if (typeof window !== "undefined") {
            localStorage.setItem("dc_cartId", this.cartId);
          }
        }

        return this.cart;
      } catch (error) {
        console.error("Create cart error:", error);
        return null;
      }
    },

    async fetchCart() {
      if (!this.cartId) {
        this.cartId = localStorage.getItem("dc_cartId");
      }

      if (!this.cartId) return null;

      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          {
            query: `
              query getCart($cartId: ID!) {
                cart(id: $cartId) { ${CART_FIELDS} }
              }
            `,
            variables: { cartId: this.cartId },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;
        this.cart = data?.cart || null;
        return this.cart;
      } catch (error) {
        console.error("Fetch cart error:", error);
        return null;
      }
    },

    async addToCart(productId, quantity = 1) {
      // Check current cart quantity
      const currentCartCount = this.cartCount;
      if (currentCartCount + quantity > 5) {
        alert(
          "Cart is limited to 5 items. Please remove some items before adding more."
        );
        return null;
      }

      if (!this.cartId) {
        await this.createCart(productId, quantity);
        return;
      }

      try {
        const mutation = `
          mutation addToCart($cartId: ID!, $lines: [CartLineInput!]!) {
            cartLinesAdd(cartId: $cartId, lines: $lines) {
              cart {
                id
                lines(first: 100) {
                  edges {
                    node {
                      id
                      quantity
                      merchandise {
                        ... on ProductVariant {
                          id
                          title
                          selectedOptions {
                            name
                            value
                          }
                          product {
                            title
                            productType
                            images(first: 1) {
                              edges {
                                node {
                                  src
                                }
                              }
                            }
                            collections(first: 1) {
                              edges {
                                node {
                                  title
                                }
                              }
                            }
                          }
                        }
                      }
                      cost {
                        totalAmount {
                          amount
                          currencyCode
                        }
                      }
                    }
                  }
                }
                estimatedCost {
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        `;

        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          {
            query: mutation,
            variables: {
              cartId: this.cart.id,
              lines: [
                {
                  merchandiseId: productId,
                  quantity: quantity,
                },
              ],
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;
        this.cart = data?.cartLinesAdd?.cart || null;
        return this.cart;
      } catch (error) {
        console.error("Add to cart error:", error);
        return null;
      }
    },

    async removeFromCart(lineId) {
      if (!this.cartId) return;

      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          {
            query: `
              mutation removeCartLines($cartId: ID!, $lineIds: [ID!]!) {
                cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                  cart { ${CART_FIELDS} }
                }
              }
            `,
            variables: { cartId: this.cartId, lineIds: [lineId] },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;
        this.cart = data?.cartLinesRemove?.cart || null;
        return this.cart;
      } catch (error) {
        console.error("Remove from cart error:", error);
        return null;
      }
    },

    async clearCart() {
      if (!this.cartId) return;

      try {
        await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          {
            query: `
              mutation deleteCart($cartId: ID!) {
                cartDelete(id: $cartId) {
                  deletedCartId
                }
              }
            `,
            variables: { cartId: this.cartId },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        this.cartId = null;
        this.cart = null;
        if (typeof window !== "undefined") localStorage.removeItem("dc_cartId");
      } catch (error) {
        console.error("Clear cart error:", error);
      }
    },

    async goToCheckout() {
      if (!this.cart) {
        await this.fetchCart();
      }

      if (this.cart?.checkoutUrl) {
        window.location.href = this.cart.checkoutUrl;
      } else {
        console.error("Checkout URL not available");
      }
    },

    async placeOrder(shippingAddress) {},
  },
  getters: {
    isAuthenticated: (state) => !!state.customerToken,
    cartCount: (state) => {
      return (
        state.cart?.lines?.edges.reduce(
          (total, line) => total + line.node.quantity,
          0
        ) || 0
      );
    },
  },
});
