import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import route_45global from "/vercel/path0/middleware/route.global.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  route_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "dev-only": () => import("/vercel/path0/middleware/dev-only.ts")
}