<template>
  <div class="menu-wrapper">
    <CollectionMenu v-show="!isMobile" />
    <ServicesMenu v-show="!isMobile" />

    <div
      ref="el"
      :class="{
        hasBackground:
          hasBackgroundComputed === true || isSearchOpenComputed === true,
        isLight: isMenuLight === true,
      }"
      class="menu-desktop"
    >
      <div class="menu-inner">
        <div class="links-container links-container-left">
          <NuxtLink
            data-type="collection"
            class="link"
            :disabled="true"
            @mouseenter="openCollectionMenu"
          >
            <span class="first">COLLECTIONS</span>
            <span class="second">COLLECTIONS</span>
          </NuxtLink>
          <NuxtLink class="link" to="/inspiration">
            <span class="first">INSPIRATION</span>
            <span class="second">INSPIRATION</span>
          </NuxtLink>
          <NuxtLink
            class="link"
            :disabled="true"
            data-type="services"
            @mouseenter="openServicesMenu"
          >
            <span class="first">DESIGN SERVICES</span>
            <span class="second">DESIGN SERVICES</span>
          </NuxtLink>
          <NuxtLink class="link" to="/the-story">
            <span class="first">THE STORY</span>
            <span class="second">THE STORY</span>
          </NuxtLink>

          <NuxtLink class="link" to="/collaborations">
            <span class="first">COLLAB</span>
            <span class="second">COLLAB</span>
          </NuxtLink>
          <NuxtLink class="link" to="/get-started">
            <span class="first">WHERE TO BUY</span>
            <span class="second">WHERE TO BUY</span>
          </NuxtLink>
          <NuxtLink class="link" to="/contact-informations">
            <span class="first">CONTACT</span>
            <span class="second">CONTACT</span>
          </NuxtLink>
        </div>
        <NuxtLink to="/">
          <LogoBlack class="logo" />
        </NuxtLink>
        <div class="links-container links-container-right">
          <!-- <NuxtLink to="/">
            <span class="first">VISUALIZER</span>
            <span class="second">VISUALIZER</span>
          </NuxtLink> -->
          <NuxtLink id="shop" to="/care-products">
            <span class="first">SHOP CARE PRODUCTS</span>
            <span class="second">SHOP CARE PRODUCTS</span>
          </NuxtLink>

          <!-- temporary hide samples link -->
          <!-- <div class="menu-divider" />

          <a class="link-sample" href="/samples">
            <span class="dot" />
            <span class="first">Complimentary Samples</span>
            <span class="second">Complimentary Samples</span></a
          > -->

          <div class="menu-divider" />

          <div class="icons-container">
            <button class="cart-icon" @click="menuStore.openCart">
              <div v-if="cartCount && cartCount > 0" class="cart-count">
                {{ cartCount }}
              </div>
              <IconCart class="icon-svg" />
            </button>
            <NuxtLink to="/account">
              <UserIcon class="icon-svg" />
            </NuxtLink>
            <button
              class="search-icon"
              @click="
                () =>
                  isSearchOpenComputed
                    ? menuStore.closeSearch()
                    : menuStore.openSearch()
              "
            >
              <SearchIcon class="icon-svg" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="cart"
      :class="{ isOpen: isCartOpen }"
      @click.self="menuStore.closeCart"
    >
      <Cart :close-cart="menuStore.closeCart" :is-open="showCart" />
    </div>

    <div class="search" :class="{ isOpen: isSearchOpen }">
      <Search :close-search="menuStore.closeSearch" />
    </div>
  </div>
</template>

<script setup>
import IconCart from "@/assets/images/icons/icon-cart.svg";
import SearchIcon from "@/assets/images/icons/search-icon.svg";
import UserIcon from "@/assets/images/icons/user-icon.svg";

import LogoBlack from "@/assets/images/icons/duchateau-logo-black.svg";
import Cart from "@/components/menu/Cart.vue";
import CollectionMenu from "@/components/menu/CollectionMenu.vue";
import Search from "@/components/menu/Search.vue";
import ServicesMenu from "@/components/menu/ServicesMenu.vue";
import { useSizes } from "@/composables/useSizes";
import { useMenuStore } from "@/stores/menuStore.js";
import { useCustomerStore } from "@/stores/shopify/customers";
import { gsap } from "gsap";

const { navbarHeight } = useSizes();
const { isMobile } = useSizes();

const menuStore = useMenuStore();
const customerStore = useCustomerStore();

const { $lenis } = useNuxtApp();

const el = ref(null);

const {
  isCollectionOpen,
  isServicesOpen,
  isCartOpen,
  isSearchOpen,
  hasMenuBackground,
  isLight,
} = storeToRefs(menuStore);

const { cartCount: cartCountStore } = storeToRefs(customerStore);

const isMenuLight = computed(() => isLight.value);
const hasBackgroundComputed = computed(() => hasMenuBackground.value);
const isSearchOpenComputed = computed(() => menuStore.isSearchOpen);
const showCart = computed(() => isCartOpen.value);
const cartCount = computed(() => cartCountStore.value);

onMounted(() => {
  customerStore.fetchCart();

  gsap.set(el.value, {
    autoAlpha: 1,
  });
  handleHovers();
});

// Watch for changes in isSearchOpen or isCartOpen to lock/unlock scroll using Lenis
watch([isSearchOpen, isCartOpen], ([searchOpen, cartOpen]) => {
  if (searchOpen || cartOpen) {
    $lenis?.stop(); // Lock the scroll
  } else {
    $lenis?.start(); // Unlock the scroll
  }
});

watch(
  () => menuStore.getDirection,
  (direction) => {
    toggleMenu(direction);
  }
);

const toggleMenu = (direction) => {
  if (direction === 1) {
    gsap.to(el.value, {
      y: -navbarHeight.value,
      overwrite: true,
      duration: 0.4,
      ease: "power2.out",
    });
  } else {
    gsap.to(el.value, {
      y: 0,
      overwrite: true,
      duration: 0.4,
      ease: "power2.inOut",
    });
  }
};

const handleHovers = () => {
  const allLinks = el.value.querySelectorAll(".link");

  allLinks.forEach((link) => {
    link.addEventListener("mouseenter", (e) => {
      const type = e.target.dataset.type;

      if (isCollectionOpen && type === "collection") return;
      if (isServicesOpen && type === "services") return;

      if (isCollectionOpen) {
        menuStore.closeCollection();
      }
      if (isServicesOpen) {
        menuStore.closeServices();
      }
    });
  });
};

const openCollectionMenu = () => menuStore.openCollection();
const openServicesMenu = () => menuStore.openServices();
</script>

<style lang="scss" scoped>
.menu-wrapper {
  position: sticky;
  width: 100%;
  inset: 0;
  z-index: 10;
}

.menu-desktop {
  padding: 0 2.4rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 52px;
  visibility: hidden;
  transition: 0.5s $ease-out-expo background-color;
}

.menu-desktop.hasBackground {
  background-color: white;
  mix-blend-mode: unset !important;
  // transition-delay: 0.5s;

  a {
    color: $black !important;
    &::after {
      background-color: $black !important;
    }
  }
  .link-sample {
    color: $black !important;
    .dot {
      background-color: $black !important;
    }
  }

  .menu-divider {
    background-color: black !important;
  }

  .logo,
  .logo :deep(path),
  path,
  svg path {
    fill: $black !important;
  }

  .icon-svg,
  .icon-svg:deep(circle),
  .icon-svg:deep(path) {
    stroke: black !important;
  }

  .link.router-link-exact-active {
    @include link-active-full;
    &::before,
    &::after {
      background-color: black;
      transform: scaleX(1);
    }
  }
}

.menu-desktop.isLight {
  mix-blend-mode: difference;
  a {
    color: $white;
    &::after {
      background-color: $white;
    }
  }
  .link-sample {
    color: $white;
    .dot {
      background-color: $white;
    }
  }
  .logo,
  .logo :deep(path),
  path,
  svg path {
    fill: $white;
  }

  .icon-svg,
  .icon-svg:deep(circle),
  .icon-svg:deep(path) {
    stroke: $white;
  }

  .link.router-link-exact-active {
    @include link-active-full;
    &::before,
    &::after {
      background-color: $white;
      transform: scaleX(1);
    }
  }

  .menu-divider {
    background-color: rgba($color: #ffffff, $alpha: 0.5);
  }
}

.menu-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.links-container {
  display: flex;
  align-items: center;
  z-index: 2;

  a {
    @include link;
    @include link-active;
    margin-right: 8px;
    text-transform: uppercase;
    color: $black;
    display: inline-block;
    overflow: hidden;
    span {
      display: inline-block;
      transition: 0.6s $ease-out-expo;
      &.second {
        position: absolute;
        inset: 0;
        transform: translateY(100%);
      }
    }
    &:hover {
      .first {
        transform: translateY(-100%);
      }
      .second {
        transform: translateY(0%);
      }
    }
  }
}

#shop {
  margin-right: 0;
}

.link.router-link-exact-active {
  @include link-active-full;
  &::before,
  &::after {
    background-color: black;
    transform: scaleX(1);
  }
}

.link-sample {
  text-transform: uppercase;
  transition: 0.5s $ease-out-expo;

  display: flex;
  justify-content: center;
  align-items: center;

  .second {
    white-space: nowrap;
    margin-left: 0.9rem;
  }

  .dot {
    display: inline-block;
    width: 5.5px;
    height: 5.5px;
    border-radius: 40px;
    background-color: $black;
    margin-right: 5px;
    transform: translateY(-0.13rem);
  }
}

.menu-divider {
  display: block;
  height: 14px;
  width: 1px;
  background-color: rgba($color: #000000, $alpha: 0.5);
  margin: 0 2rem;
}

.logo {
  width: 18px;
  height: 24px;
  margin-top: -12px;
  margin-left: -9px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
}

.icons-container {
  height: 2rem;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  svg {
    cursor: pointer;
    :deep(path),
    path {
      width: 100%;
    }
  }
  a {
    margin: 0;
  }

  .cart-icon {
    position: relative;
    overflow: visible;

    .cart-count {
      position: absolute;
      width: 14px;
      height: 14px;
      top: -7px;
      right: -7px;
      background-color: $black;
      color: $white;
      border-radius: 50%;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1200px) {
  .logo {
    left: 53%;
  }
}

.icon-svg {
  height: 15px;
  width: 15px;
}

.cart {
  position: fixed;
  inset: 0;
  z-index: 11;
  pointer-events: none;
  background: rgba(0, 0, 0, 0);
  transition: 1s $ease-out-expo;

  &.--hidden {
    visibility: hidden;
  }

  &.isOpen {
    pointer-events: all;
    background: rgba(0, 0, 0, 0.5);
  }
  @include desktop {
    cursor: pointer;
  }
}
</style>
