import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M7.91031 8.68C9.75494 8.68 11.2503 7.18463 11.2503 5.34C11.2503 3.49537 9.75494 2 7.91031 2C6.06568 2 4.57031 3.49537 4.57031 5.34C4.57031 7.18463 6.06568 8.68 7.91031 8.68Z",
      stroke: "black"
    }, null, -1),
    _createElementVNode("path", {
      d: "M1.5 15.2197V14.9197C1.5 12.5897 3.39 10.6997 5.72 10.6997H10.1C12.43 10.6997 14.32 12.5897 14.32 14.9197V15.2197",
      stroke: "black"
    }, null, -1)
  ])))
}
export default { render: render }