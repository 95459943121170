export const useMobile = () => {
  const isMobile = ref(false);

  const checkIsMobile = () => {
    isMobile.value = window.matchMedia("(max-width: 1023px)").matches;
  };

  onMounted(() => {
    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", checkIsMobile);
  });

  return { isMobile };
};
