
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91uid_93_45zQsBGvb3HJC3hvzEpSFWaH1MbBJRnoN_456suD6Xnc34Meta } from "/vercel/path0/pages/[category]/[uid].vue?macro=true";
import { default as AccountMHCwEFdkYePWYJFgYJK_qPGF1F_y5SpY2KONJAPKtb0Meta } from "/vercel/path0/pages/account/Account.vue?macro=true";
import { default as indexzstSAbUVOxFyrG4epW9LiK9NjJX7tD8cF30wOEyhWF4Meta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as LoginSignUpnQzb1xpoYaESQUGFKAmMC7iOhcbLKyMflPLBAVUPkCoMeta } from "/vercel/path0/pages/account/LoginSignUp.vue?macro=true";
import { default as Addressesxb8bHsdYVfIifHgzLm7ajlaWq9kCJEeSF5A5xab36VEMeta } from "/vercel/path0/pages/account/sections/Addresses.vue?macro=true";
import { default as OrdersR8aP3Dy79orRbZjRDmfez1Wkhmy2ll2kNcgIxGupWNcMeta } from "/vercel/path0/pages/account/sections/Orders.vue?macro=true";
import { default as ProfilefobujU40h_1TAkgoCOCC4rsJhT3vYs9_45_45i1Ex0pRgyIMeta } from "/vercel/path0/pages/account/sections/Profile.vue?macro=true";
import { default as WishlistINFktLrLUz5FW_45Lr93QOpC209Nh2t9PpechIs_45hO9jAMeta } from "/vercel/path0/pages/account/sections/Wishlist.vue?macro=true";
import { default as appliancesQQmGVB1ZDNFFX2DlRFR1JAUSiQs0L4gp83L0vl2qkeoMeta } from "/vercel/path0/pages/appliances.vue?macro=true";
import { default as bathsS4mxqbxfwU1eFqu1NhCaJ4C6wgdgExnXZVqlMDoECmYMeta } from "/vercel/path0/pages/baths.vue?macro=true";
import { default as care_45productsUJ_45PEOpMZtktwtFhSNgP3kSvAs4nHeid1KgY45YNdlYMeta } from "/vercel/path0/pages/care-products.vue?macro=true";
import { default as _index9K4MJ0CiVARUvCNoMHED2rLCa6zNtoBoMbuPO_45v4c7EMeta } from "/vercel/path0/pages/Checkout/_index.vue?macro=true";
import { default as CheckoutFfyzDRjS9U2zkSx15eD_450ShtlLdittUvNnEzv1g1TyAMeta } from "/vercel/path0/pages/Checkout/Checkout.vue?macro=true";
import { default as collaborationsGrjjkz7qz6ZotudSpbrt9gMKe9yGQvP_pMF6MdwXP68Meta } from "/vercel/path0/pages/collaborations.vue?macro=true";
import { default as _91uid_93kHO1fBmZW89vVj3cfqNURl2pNrIoHk4E36o44184EAwMeta } from "/vercel/path0/pages/collection/[uid].vue?macro=true";
import { default as contact_45informations8M8HtxWfVr29xKC7sE_457W2QD86qlYfK39aRn1f8C604Meta } from "/vercel/path0/pages/contact-informations.vue?macro=true";
import { default as design_45servicesPjwVMdM80pfWGcma69Pi3blI_453azexC6c_BW2RypxzkMeta } from "/vercel/path0/pages/design-services.vue?macro=true";
import { default as designer_45collectiveHh12_45WlFfaVvDZ3_6ZwM8Qcga72QCWqwPK03NVPut6QMeta } from "/vercel/path0/pages/designer-collective.vue?macro=true";
import { default as doorsdiyptuXmjYMAYN_syz9gAv_45IfPbH0GPaReQ_O4y2BgMMeta } from "/vercel/path0/pages/doors.vue?macro=true";
import { default as dugoodQVoBowue22yTX0G694WzWvZ_Lxg5fvLJsV1Gn9IMuP8Meta } from "/vercel/path0/pages/dugood.vue?macro=true";
import { default as floorsWORVLm5_hOoKOSvOAOfpZJgqoe40ngSNi7c53OQ3keoMeta } from "/vercel/path0/pages/floors.vue?macro=true";
import { default as furnitureYTSL34boZdGX0ZT41CkFPq74lsnoN8B5R_VdQRtZXPAMeta } from "/vercel/path0/pages/furniture.vue?macro=true";
import { default as get_45started0Xl7_Rtoc6qOoE2cIpn1hHSXpcdtA5gS6n465HvJYAsMeta } from "/vercel/path0/pages/get-started.vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as inspirationOOKnHzXUQ_FTG4ji4m6q0ZusB8QvqtdWqw8vqIo_APsMeta } from "/vercel/path0/pages/inspiration.vue?macro=true";
import { default as join_45our_45teamXzA2gm_45vUsHa5wmMY5SZQ7hm4gJTdnB0Y3Jdp6mQPjsMeta } from "/vercel/path0/pages/join-our-team.vue?macro=true";
import { default as kitchens66diF3jcv5zi3XhPnix_POdPPimnE5UNcvs3HpeEWwUMeta } from "/vercel/path0/pages/kitchens.vue?macro=true";
import { default as sales_45policyvTm8WUKkDipynBVKkkVhnZQXeTE40cR02kEe1Q1XiaYMeta } from "/vercel/path0/pages/legal/sales-policy.vue?macro=true";
import { default as terms_45and_45conditionsxCiIrhmIYPloQj_QIkZU36YEhmmva6TU5Blam_v1lNsMeta } from "/vercel/path0/pages/legal/terms-and-conditions.vue?macro=true";
import { default as terms_45of_45usedkfBpSL5RMPtL0sVa1w5HKxsaJW_vy0vSfRaqwbou2cMeta } from "/vercel/path0/pages/legal/terms-of-use.vue?macro=true";
import { default as outdoorbMTjAoI5TDWdeH0nDS7ib0ZaXxChPX5ZuDdLU1lZ_DoMeta } from "/vercel/path0/pages/outdoor.vue?macro=true";
import { default as previewq3W1hkuo40KpEaTaT_OeXYiBIYrunqAorNBen9O_4598cMeta } from "/vercel/path0/pages/preview.vue?macro=true";
import { default as _91slug_93vB1189u3xMIvQOXvBYWJJBKb6wsNTufUdOK7AnQWdNMMeta } from "/vercel/path0/pages/product/[slug].vue?macro=true";
import { default as _91slug_93iMjCUBwhwzuBZeAHcomoyN02VfiBsb_c3Jec0UJb93UMeta } from "/vercel/path0/pages/sample/[slug].vue?macro=true";
import { default as samplesFhZ_R_45066GlD5zB47vcRclrOzL444nQ4FHVHlxFTGzUMeta } from "/vercel/path0/pages/samples.vue?macro=true";
import { default as searchE0nZQqeAMWsUG5cnJAPfFVP401IiagPhLDCR_YaJLbcMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as slice_45simulator_OjKnhIyDXYQMh_451hfsvK1gAKx2giehyTcKMoPBK0RYMeta } from "/vercel/path0/pages/slice-simulator.vue?macro=true";
import { default as the_45storylJP7HjIgWC2hnhWyd9wnSPRmnQfr6i5eBrtiOWe2iaYMeta } from "/vercel/path0/pages/the-story.vue?macro=true";
import { default as walls_45ceilingsAQeZe7_45M832J3vhx0R4j0c_iqcZ2AA4aTYjdaK9UC6kMeta } from "/vercel/path0/pages/walls-ceilings.vue?macro=true";
import { default as wardrobeA9YVBMxXNyldHewqtzpN6AffRD7Oa57lc5tDjWjUdqUMeta } from "/vercel/path0/pages/wardrobe.vue?macro=true";
export default [
  {
    name: "category-uid",
    path: "/:category()/:uid()",
    component: () => import("/vercel/path0/pages/[category]/[uid].vue")
  },
  {
    name: "account-Account",
    path: "/account/Account",
    component: () => import("/vercel/path0/pages/account/Account.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "account-LoginSignUp",
    path: "/account/LoginSignUp",
    component: () => import("/vercel/path0/pages/account/LoginSignUp.vue")
  },
  {
    name: "account-sections-Addresses",
    path: "/account/sections/Addresses",
    component: () => import("/vercel/path0/pages/account/sections/Addresses.vue")
  },
  {
    name: "account-sections-Orders",
    path: "/account/sections/Orders",
    component: () => import("/vercel/path0/pages/account/sections/Orders.vue")
  },
  {
    name: "account-sections-Profile",
    path: "/account/sections/Profile",
    component: () => import("/vercel/path0/pages/account/sections/Profile.vue")
  },
  {
    name: "account-sections-Wishlist",
    path: "/account/sections/Wishlist",
    component: () => import("/vercel/path0/pages/account/sections/Wishlist.vue")
  },
  {
    name: "appliances",
    path: "/appliances",
    component: () => import("/vercel/path0/pages/appliances.vue")
  },
  {
    name: "baths",
    path: "/baths",
    component: () => import("/vercel/path0/pages/baths.vue")
  },
  {
    name: "care-products",
    path: "/care-products",
    component: () => import("/vercel/path0/pages/care-products.vue")
  },
  {
    name: "Checkout-_index",
    path: "/Checkout/_index",
    component: () => import("/vercel/path0/pages/Checkout/_index.vue")
  },
  {
    name: "Checkout-Checkout",
    path: "/Checkout/Checkout",
    component: () => import("/vercel/path0/pages/Checkout/Checkout.vue")
  },
  {
    name: "collaborations",
    path: "/collaborations",
    component: () => import("/vercel/path0/pages/collaborations.vue")
  },
  {
    name: "collection-uid",
    path: "/collection/:uid()",
    component: () => import("/vercel/path0/pages/collection/[uid].vue")
  },
  {
    name: "contact-informations",
    path: "/contact-informations",
    component: () => import("/vercel/path0/pages/contact-informations.vue")
  },
  {
    name: "design-services",
    path: "/design-services",
    component: () => import("/vercel/path0/pages/design-services.vue")
  },
  {
    name: "designer-collective",
    path: "/designer-collective",
    component: () => import("/vercel/path0/pages/designer-collective.vue")
  },
  {
    name: "doors",
    path: "/doors",
    component: () => import("/vercel/path0/pages/doors.vue")
  },
  {
    name: "dugood",
    path: "/dugood",
    component: () => import("/vercel/path0/pages/dugood.vue")
  },
  {
    name: "floors",
    path: "/floors",
    component: () => import("/vercel/path0/pages/floors.vue")
  },
  {
    name: "furniture",
    path: "/furniture",
    component: () => import("/vercel/path0/pages/furniture.vue")
  },
  {
    name: "get-started",
    path: "/get-started",
    component: () => import("/vercel/path0/pages/get-started.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "inspiration",
    path: "/inspiration",
    component: () => import("/vercel/path0/pages/inspiration.vue")
  },
  {
    name: "join-our-team",
    path: "/join-our-team",
    component: () => import("/vercel/path0/pages/join-our-team.vue")
  },
  {
    name: "kitchens",
    path: "/kitchens",
    component: () => import("/vercel/path0/pages/kitchens.vue")
  },
  {
    name: "legal-sales-policy",
    path: "/legal/sales-policy",
    component: () => import("/vercel/path0/pages/legal/sales-policy.vue")
  },
  {
    name: "legal-terms-and-conditions",
    path: "/legal/terms-and-conditions",
    component: () => import("/vercel/path0/pages/legal/terms-and-conditions.vue")
  },
  {
    name: "legal-terms-of-use",
    path: "/legal/terms-of-use",
    component: () => import("/vercel/path0/pages/legal/terms-of-use.vue")
  },
  {
    name: "outdoor",
    path: "/outdoor",
    component: () => import("/vercel/path0/pages/outdoor.vue")
  },
  {
    name: "preview",
    path: "/preview",
    component: () => import("/vercel/path0/pages/preview.vue")
  },
  {
    name: "product-slug",
    path: "/product/:slug()",
    component: () => import("/vercel/path0/pages/product/[slug].vue")
  },
  {
    name: "sample-slug",
    path: "/sample/:slug()",
    component: () => import("/vercel/path0/pages/sample/[slug].vue")
  },
  {
    name: "samples",
    path: "/samples",
    meta: samplesFhZ_R_45066GlD5zB47vcRclrOzL444nQ4FHVHlxFTGzUMeta || {},
    component: () => import("/vercel/path0/pages/samples.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "slice-simulator",
    path: "/slice-simulator",
    component: () => import("/vercel/path0/pages/slice-simulator.vue")
  },
  {
    name: "the-story",
    path: "/the-story",
    component: () => import("/vercel/path0/pages/the-story.vue")
  },
  {
    name: "walls-ceilings",
    path: "/walls-ceilings",
    component: () => import("/vercel/path0/pages/walls-ceilings.vue")
  },
  {
    name: "wardrobe",
    path: "/wardrobe",
    component: () => import("/vercel/path0/pages/wardrobe.vue")
  }
]