// Data for the main section of the menu
export const mainMenuData = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Collections",
    categories: [
      { name: "Floors", link: "/floors" },
      { name: "Walls & Ceilings", link: "/walls-ceilings" },
      { name: "Kitchens", link: "/kitchens" },
      { name: "Outdoor", link: "/outdoor" },
      { name: "Bath", link: "/baths" },
      { name: "Wardrobe", link: "/wardrobe" },
      { name: "Doors", link: "/doors" },
      { name: "Appliances", link: "/appliances" },
    ],
  },
  {
    title: "Shop Care Products",
    link: "/care-products",
  },
  {
    title: "Inspiration",
    link: "/inspiration",
  },
  {
    title: "Design Services",
    categories: [
      {
        name: "Complimentary Design Services",
        link: "/design-services",
      },
      {
        name: "Designer Collective",
        link: "/designer-collective",
      },
    ],
  },
  {
    title: "Get Started",
    link: "/get-started",
  },
  {
    title: "The Story",
    link: "/the-story",
  },
  {
    title: "Collab",
    link: "/collaborations",
  },
];

// Data for the bottom section of the menu
export const bottomMenuData = [
  {
    title: "Client",
    categories: [
      // { name: "REGISTER YOUR FLOOR", link: "/contact-informations" },
      // temporary hide samples link
      // { name: "FREE SAMPLE", link: "/samples" },
      // { name: "VISUALIZER", link: "/visualizer" },
      { name: "INFORMATION", link: "/contact-informations" },
    ],
  },
  {
    title: "Social",
    categories: [
      {
        name: "INSTAGRAM",
        link: "https://www.instagram.com/duchateauofficial/",
      },
      { name: "FACEBOOK", link: "https://www.facebook.com/duchateaufloors" },
      { name: "LINKEDIN", link: "https://www.linkedin.com/company/duchateau" },
      { name: "PINTEREST", link: "https://www.pinterest.com/duchateaufloors/" },
      { name: "VIMEO", link: "https://vimeo.com/duchateau" },
    ],
  },
  {
    title: "Legal",
    categories: [
      { name: "SALES POLICY", link: "/legal/sales-policy" },
      { name: "TERMS OF USE", link: "/legal/terms-of-use" },
      { name: "TERMS AND CONDITIONS", link: "legal/terms-and-conditions" },
    ],
  },
];
