<template>
  <button
    :class="{
      dark: props.dark,
      transparent: props.transparent,
      video: props.video,
    }"
    class="round-button"
    :type="type"
  >
    <span>{{ label }}</span>
  </button>
</template>

<script setup>
const props = defineProps({
  dark: {
    type: Boolean,
    default: false,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
  video: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "Button",
  },
  type: {
    type: String,
    default: "button",
  },
});
</script>

<style lang="scss" scoped>
.round-button {
  @include body-regular;
  background-color: transparent;
  border: solid 1px $black;
  text-align: center;
  padding: 1.8rem 7rem;
  border-radius: 3.5rem;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  border: solid 1px $black;
  transition: 0.8s $ease-out-expo;
  &.dark {
    background-color: $black;
    color: $white;
    border: solid 1px $black;
  }
  &.transparent {
    background-color: transparent;
  }
  span {
    transform: translateY(1px);
    pointer-events: none;
  }
}
@include desktop {
  .round-button {
    padding: 1.8rem 4.2rem;

    &:hover {
      background-color: $black;
      color: $white;
      border: solid 1px $black;
    }
    &.dark {
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.7);
        border: solid 1px rgba($color: #000000, $alpha: 0.7);
      }
    }
    &.video {
      background: rgba(0, 0, 0, 0.33);
      color: $white;
      border: none;
    }
  }
}
</style>
