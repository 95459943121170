import { defineStore } from "pinia";

export const useMenuMobileStore = defineStore("menuMobile", {
  state: () => ({
    isOpen: false,
  }),
  actions: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  },
  getters: {
    isMenuOpen: (state) => state.isOpen,
  },
});
