<template>
  <NuxtPicture
    v-if="props.image.url || props.image.src"
    ref="img"
    class="img-el"
    :src="props.image.url || props.image.src"
    :sizes="useImgSizes(...props.sizes)"
    :alt="props.image.alt"
    :class="{ '--shopify': props.shopify }"
    :loading="props.lazy ? 'lazy' : null"
    v-bind="$attrs"
  />
</template>

<script setup>
import { gsap } from "gsap";
import { ref, onMounted, onUnmounted } from "vue";

const img = ref(null);

const props = defineProps({
  image: Object,
  sizes: Array,
  shopify: Boolean,
  lazy: {
    type: Boolean,
    default: false,
  },
});

let observer;

onMounted(() => {
  if (!props.image.url) return;

  observer = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  if (img.value) {
    observer.observe(img.value.$el);
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});

const handleIntersection = (entries) => {
  entries.forEach((entry) => {
    const image = entry.target.querySelector("img");

    if (entry.isIntersecting) {
      // L'image est dans le viewport
      if (image.complete) {
        animateImage(image);
      } else {
        image.onload = () => animateImage(image);
      }
    } else {
      // L'image est hors du viewport
      if (image.complete) {
        showImageImmediately(image);
      } else {
        image.onload = () => showImageImmediately(image);
      }
    }

    observer.unobserve(entry.target);
  });
};

const animateImage = (image) => {
  gsap.to(image, {
    opacity: 1,
    duration: 0.3,
    ease: "expo.out",
  });
};

const showImageImmediately = (image) => {
  gsap.to(image, { opacity: 1, duration: 0.3, ease: "expo.out" });
};

defineExpose({ img });
</script>

<style lang="scss" scoped>
:deep(img) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
}

.--shopify:deep(img) {
  opacity: 1;
}
</style>
