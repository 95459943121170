<template>
  <NuxtLink
    ref="linkElement"
    :class="{ light: props.light }"
    class="link link-active"
    :to="props.link"
    :target="props.external ? '_blank' : ''"
    >{{ props.label }}</NuxtLink
  >
</template>

<script setup>
const props = defineProps({
  link: {
    default: "",
    required: false,
  },
  label: String,
  light: Boolean,
  external: Boolean,
});

const linkElement = ref(null);

defineExpose({ linkElement });
</script>

<style lang="scss" scoped>
.link {
  @include link-no-hover;
  color: $black;
  text-transform: uppercase;
  height: fit-content;
  will-change: transform;
  &.light {
    color: $white;
    &::before,
    &::after {
      background-color: $white;
    }
  }
}

.link-active {
  width: fit-content;
  text-decoration: none;
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.link-active::before,
.link-active::after {
  transition: 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}

.link-active::before,
.link-active::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
}

.link-active::before {
  background-color: $black;
  height: 1.5px;
  bottom: 0rem;
  transform-origin: 100% 50%;
  transform: scaleX(1);
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}

.link-active::after {
  background-color: $black;
  height: 1.5px;
  bottom: 0rem;
  transform-origin: 0% 50%;
  transform: scaleX(0);
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
  transition-delay: 0.1s;
}

.link-active:hover::before {
  transform: scaleX(0);
  transform-origin: 100% 50%;
}

.link-active:hover::after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
</style>
