<template>
  <!-- Root Element -->
  <div class="search-wrapper" :class="{ isOpen: isSearchOpen }">
    <!-- Overlay -->
    <div
      class="overlay"
      :class="{ isOpen: isSearchOpen }"
      @click="closeSearch"
    />

    <!-- Search Container -->
    <div class="search-container" :class="{ isOpen: isSearchOpen }">
      <!-- Search Input Area -->
      <div class="search-header">
        <div class="search-input-container">
          <div class="search-input-icon">
            <SearchIcon class="icon-svg" />
          </div>
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Start typing to search"
            @input="handleInput"
            @keyup.enter="redirectToSearch"
          />
          <!-- Right Arrow Button to Trigger Search -->
          <button class="submit-input-icon" @click="redirectToSearch">
            <RightArrowIcon class="icon-svg" />
          </button>
        </div>
      </div>

      <!-- Suggestions Section -->
      <div v-if="suggestions.length" class="suggestions">
        <h4>Suggestions</h4>
        <ul>
          <li v-for="product in suggestions" :key="product.id">
            <NuxtLink
              :to="`/product/${product.handle}`"
              :title="product.title"
              @click="
                (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  closeSearch();
                }
              "
            >
              <div><SearchIcon class="icon-svg" /></div>
              <span v-html="highlightText(product.title, searchQuery.value)" />
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import SearchIcon from "@/assets/images/icons/search-icon.svg";
import RightArrowIcon from "@/assets/images/icons/right-arrow-icon.svg";
import { useMenuStore } from "@/stores/menuStore.js";

import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useProductStore } from "@/stores/shopify/products";
const menuStore = useMenuStore();
const { isSearchOpen } = storeToRefs(menuStore);

const props = defineProps({
  closeSearch: Function,
});

const router = useRouter();
const productStore = useProductStore();
const foundProducts = ref([]);
const searchQuery = ref("");
const topSearches = ["Atelier", "Signature", "Guild", "Wall & Ceilings"];
let debounceTimeout = null;

const suggestions = computed(() => {
  if (!searchQuery.value.trim()) return [];
  const filteredSuggestions = foundProducts.value.filter((product) =>
    product.title.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
  return filteredSuggestions;
});

// Watch for changes in searchQuery
watch(searchQuery, (newValue) => {
  handleInput();
});

// Debounced search function
function handleInput() {
  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(async () => {
    if (searchQuery.value.trim()) {
      const result = await productStore.searchProducts(searchQuery.value, 40);
      if (result.success) {
        // Update foundProducts.value with the fetched results
        foundProducts.value = result.products;
      } else {
        console.error("Search Error:", result.errors);
        foundProducts.value = [];
      }
    } else {
      foundProducts.value = [];
    }
  }, 1000);
}

// Function to redirect to the search results page
function redirectToSearch() {
  if (searchQuery.value.trim()) {
    router.push({ path: "/search", query: { query: searchQuery.value } });
  }
}

function highlightText(text) {
  if (!searchQuery.value) return text;

  // Create a case-insensitive regular expression
  const regex = new RegExp(`${searchQuery.value}`, "gi");

  // Split the text based on the regex and highlight matches
  return text.replace(regex, (match) => {
    return `<span style="text-decoration: underline; text-decoration-thickness: 1px;">${match}</span>`;
  });
}

// Handle Top Search Click
async function handleSearch(item) {
  searchQuery.value = item;
  redirectToSearch();
}
</script>

<style lang="scss" scoped>
.search-wrapper {
  position: fixed;
  top: 52px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: none;
  &.isOpen {
    pointer-events: all;
  }
}

.overlay {
  position: fixed;
  top: 52px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999; /* Make sure the overlay is behind the search container */
  background-color: rgba(0, 0, 0, 0);
  height: calc(100vh - 52px);
  pointer-events: none;
  transition: 1s $ease-out-expo;

  @include tablet {
    top: 66px;
    height: calc(100vh - 66px);
  }

  &.isOpen {
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: all;
  }
}

.search-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 47px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above the overlay */
  transition: 1s $ease-out-expo;

  transform: translateY(-150%);

  @include tablet {
    transform: translateY(-170%);
  }

  &.isOpen {
    transform: translateY(0%);
  }

  @include tablet {
    top: 65px;
  }

  @include desktop {
    top: 52px;
  }

  h4 {
    @include link;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
}

.search-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-input-container {
  width: 100%;
  position: relative;
}

input {
  width: 100%;
  height: 67px;
  font-size: 16px;
  background: #f4f4f4;
  outline: none;
  padding-left: 70px;
  padding-right: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #050505;

  &::placeholder {
    color: #050505;
  }
}

.search-input-icon {
  position: absolute;
  left: 35px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;

  .icon-svg,
  .icon-svg:deep(circle),
  .icon-svg:deep(path) {
    stroke: black !important;
  }
}

.submit-input-icon {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  opacity: 0.6;

  .icon-svg,
  .icon-svg:deep(circle),
  .icon-svg:deep(path) {
    stroke: black !important;
  }
}

.suggestions,
.top-searches {
  margin-top: 25px;
  margin-left: 70px;
  margin-bottom: 60px;
}

.suggestions {
  margin-bottom: 30px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  cursor: pointer;

  button,
  a {
    @include caption-medium;
    opacity: 0.6;
    transition: opacity 0.2s;
    display: flex;
    gap: 5px;

    svg {
      width: 14px;
      transform: translateY(4px);
    }

    &:hover {
      opacity: 1;
    }
  }
}

li span {
  margin-right: 8px;
}

.submit-input-icon {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  .icon-svg {
    stroke: black !important;
  }
}
</style>
