import { defineStore } from "pinia";

export const useMenuStore = defineStore("menu", {
  state: () => ({
    collectionOpen: false,
    servicesOpen: false,
    cartOpen: false,
    isLight: false,
    searchOpen: false,
    _stayLight: false,
    hasBackground: false,
    direction: 1,
  }),
  actions: {
    openCollection() {
      this.collectionOpen = true;
    },
    closeCollection() {
      this.collectionOpen = false;
    },
    openServices() {
      this.servicesOpen = true;
    },
    closeServices() {
      this.servicesOpen = false;
    },
    openCart() {
      this.cartOpen = true;
    },
    closeCart() {
      this.cartOpen = false;
    },
    openSearch() {
      this.searchOpen = true;
    },
    closeSearch() {
      this.searchOpen = false;
    },
    setLight(bool) {
      this.isLight = bool;
    },
    stayLight(bool) {
      this._stayLight = bool;
    },
    setHasBackground(bool) {
      this.hasBackground = bool;
    },
    reset() {
      this.isLight = false;
      this.hasBackground = false;
    },
    setDirection(direction) {
      this.direction = direction;
    },
  },
  getters: {
    isCollectionOpen: (state) => state.collectionOpen,
    isServicesOpen: (state) => state.servicesOpen,
    isCartOpen: (state) => state.cartOpen,
    isMenuLight: (state) => state.isLight,
    isSearchOpen: (state) => state.searchOpen,
    shouldStayLight: (state) => state._stayLight,
    hasMenuBackground: (state) => state.hasBackground,
    getDirection: (state) => state.direction,
  },
});
