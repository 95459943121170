<template>
  <div ref="el" class="cart-container" :class="{ isOpen: isCartOpen }">
    <div class="cart-header">
      <h2>Your Shopping Cart</h2>
      <button class="close-button" @click="closeCart">×</button>
    </div>

    <!-- Cart Items List -->
    <div v-if="cart && cart.lines.edges.length > 0" class="cart-items">
      <div
        v-for="line in cart.lines.edges"
        :key="line.node.id"
        class="cart-item"
      >
        <!-- Close Button for Each Cart Item -->
        <button class="remove-item-button" @click="removeItem(line.node.id)">
          ×
        </button>

        <img
          :src="line.node.merchandise.product.images?.edges?.[0]?.node?.src"
          alt="Product Image"
          class="product-image"
        />
        <div class="item-details">
          <div v-if="line.node.merchandise.product.productType !== 'Care'">
            <h4 class="bold">
              {{ line.node.merchandise.product.productType }}
            </h4>
            <p class="bold">
              {{
                line.node.merchandise.product.collections?.edges?.[0]?.node
                  ?.title
              }}
            </p>
            <p>
              Color:
              {{
                line.node.merchandise.product.displayTitle?.value ||
                line.node.merchandise.product.title
              }}
            </p>
            <p
              v-if="line.node.merchandise.selectedOptions?.length"
              class="variant-option"
            >
              {{ formatVariantOptions(line.node.merchandise.selectedOptions) }}
            </p>
          </div>
          <div v-else>
            <h4 class="bold">
              {{ line.node.merchandise.product.title }}
            </h4>
          </div>
          <div>
            <p>
              Qty: {{ line.node.quantity }} | Price:
              {{ formatPrice(line.node.cost?.totalAmount) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Cart Totals -->
    <div class="cart-totals">
      <div class="subtotal">
        <span>Subtotal</span>
        <span>{{ formattedSubtotal }}</span>
      </div>
      <div class="shipping">
        <span>Shipping</span>
        <span>Calculated at Checkout</span>
      </div>
    </div>

    <div class="checkout-button">
      <!-- This is the button that use the Shopify checkout -->
      <RoundButton
        :dark="true"
        label="Checkout"
        @click="customerStore.goToCheckout"
      />
      <!-- <RoundButton
        :dark="true"
        label="Checkout"
        @click="window.open('/checkout')"
      /> -->
    </div>
  </div>
</template>

<script setup>
import { useMenuStore } from "@/stores/menuStore.js";
import { useCustomerStore } from "@/stores/shopify/customers";
import { formatPrice } from "@/utils/shopify";

const menuStore = useMenuStore();
const customerStore = useCustomerStore();

const el = shallowRef(null);

const cart = computed(() => customerStore.cart);
const formattedSubtotal = computed(() => {
  if (!cart.value?.estimatedCost?.totalAmount) return "$0";
  const amount = cart.value.estimatedCost?.totalAmount?.amount;
  return `$${parseFloat(amount).toFixed(2)}`;
});

const { isCartOpen } = storeToRefs(menuStore);

const props = defineProps({
  closeCart: Function,
  isOpen: Boolean,
});

function removeItem(lineId) {
  customerStore.removeFromCart(lineId);
}

function formatVariantOptions(options) {
  return options
    .filter((option) => option.name.toLowerCase() !== "title")
    .map((option) => option.value)
    .join(" - ");
}

onMounted(async () => {
  await customerStore.fetchCart();
});
</script>

<style lang="scss" scoped>
.cart-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100dvh;
  background: #fff;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transition: 1s $ease-out-expo;
  cursor: initial;
  width: 100%;

  @include desktop {
    width: 420px;
    transform: translateX(100%);
    &.isOpen {
      transform: translateX(0);
    }
  }
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px $black;
  padding-bottom: 10px;
  margin-bottom: 10px;

  h2 {
    @include link;
    text-transform: uppercase;
  }
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transform: translateY(-2px);
}

.cart-items {
  flex-grow: 1;
  overflow-y: auto;
  margin: 20px 0;
}

.cart-item {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  padding: 5px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.remove-item-button {
  position: absolute;
  top: 0px;
  right: 7px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #999;
}

.product-image {
  width: 117px;
  height: 83px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 3px;
}

.item-details {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div > * {
    @include body-regular;
    opacity: 0.5;

    &.bold {
      opacity: 1;
    }
  }
}

.cart-totals {
  border-top: solid 1px $black;
  border-bottom: solid 1px $black;
  margin-top: auto;
  margin-bottom: 20px;
  padding: 20px 0;
}

.subtotal,
.shipping {
  @include body-regular;
  display: flex;
  justify-content: space-between;
}

.shipping {
  margin-top: 10px;
  opacity: 0.5;
}

.checkout-button {
  width: 100%;

  > button {
    width: 100%;
  }
}

.variant-details {
  margin-top: 0.5rem;
}

.variant-option {
  @include body-small;
  opacity: 0.7;
  margin-bottom: 0.2rem;
}
</style>
