// middleware/route.js

export default defineNuxtRouteMiddleware((to, from) => {
  const pinia = usePinia();
  const transitionStore = useTransitionStore(pinia);

  if (to.path === "/samples") {
    transitionStore.setIsSamples(true);
  } else {
    transitionStore.setIsSamples(false);
  }
});
