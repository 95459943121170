<template>
  <div ref="el" class="menu-mobile-overlay" data-lenis-prevent>
    <div class="menu-content">
      <div class="menu-list">
        <div
          v-for="(item, index) in mainList"
          ref="listItem"
          :key="index"
          class="list-item"
        >
          <NuxtLink
            :to="item.categories ? '' : item.link"
            @click="(e) => onLinkClick(e)"
          >
            <div class="surface" @click="toggleSection(index, item.categories)">
              <h2
                ref="name"
                class="category-name"
                :class="{ '--list': item.categories }"
              >
                {{ item.title }}
                <span v-if="item.categories" class="category-name--number"
                  >(+{{ item.categories.length }})</span
                >
              </h2>
              <div
                v-if="item.categories"
                class="cross"
                :class="{ open: openSections[index] }"
              >
                <div class="cross-vertical" />
                <div class="cross-horizontal" />
              </div>
            </div>
          </NuxtLink>
          <div ref="linkSections" class="links" :class="`list-${index}`">
            <div class="links-container">
              <template v-for="link in item.categories" :key="link.name">
                <NuxtLink
                  class="link"
                  :to="link.link"
                  @click="(e) => onLinkClick(e)"
                >
                  <span>
                    {{ link.name }}
                  </span>
                </NuxtLink>
              </template>
            </div>
          </div>
        </div>
      </div>

      <!-- temporary hide samples link -->

      <!-- <div class="link-sample" @click="(e) => onLinkClick(e)">
        <span class="dot" />
        <a href="/samples">
          <span> Complimentary Samples </span>
        </a>
      </div> -->

      <!-- <div class="bottom-list">
        <div
          v-for="column in bottomList"
          ref="bottomColumn"
          class="list-column"
        >
          <h2 class="bottom-title">
            <span>
              {{ column.title }}
            </span>
          </h2>

          <NuxtLink
            v-for="link in column.categories"
            class="bottom-link"
            :to="link.link"
          >
            <span>{{ link.name }}</span>
          </NuxtLink>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { bottomMenuData, mainMenuData } from "@/data/menuData.js";
import { useMenuMobileStore } from "@/stores/menuMobileStore.js";
import { gsap } from "gsap";

const mainList = ref(mainMenuData);
const bottomList = ref(bottomMenuData);
const openSections = ref(mainList.value.map(() => false));
const linkSections = ref([]);

const bottomColumn = ref([]);

const menuMobileStore = useMenuMobileStore();

const { isMenuOpen } = storeToRefs(menuMobileStore);

const { $lenis } = useNuxtApp();

watch(isMenuOpen, (newVal) => {
  toggleMenu(newVal);
});

const el = ref(null);

const listItem = ref([]);
const name = ref([]);

let ctx;

onMounted(() => {
  setupElements();
});

const setupElements = () => {
  gsap.set(el.value, {
    yPercent: -100,
    autoAlpha: 1,
  });

  gsap.set(name.value, {
    yPercent: -110,
  });

  linkSections.value.forEach((section) => {
    gsap.set(section, { height: 0 });
  });

  bottomColumn.value.forEach((column) => {
    const allSpans = column.querySelectorAll("span");

    gsap.set(allSpans, {
      yPercent: -100,
    });
  });
};

const toggleMenu = (state) => {
  if (state === true) {
    openMenu();
  } else {
    closeMenu();
  }
};

const openMenu = () => {
  $lenis.stop();

  ctx = gsap.context(() => {
    const tl = gsap.timeline();

    tl.to(el.value, {
      yPercent: 0,
      duration: 1.4,
      delay: -0.2,
      ease: "power4.inOut",
    });

    tl.to(
      listItem.value,
      {
        "--width": "100%",
        duration: 1,
        delay: 0.05,
        ease: "power4.inOut",
        stagger: 0.04,
      },
      0
    );

    tl.to(
      name.value,
      {
        yPercent: 0,
        duration: 1,
        delay: 0.05,
        ease: "power4.inOut",
        stagger: 0.04,
      },
      0
    );

    bottomColumn.value.forEach((column) => {
      const allSpans = column.querySelectorAll("span");

      gsap.to(allSpans, {
        yPercent: 0,
        delay: 0.45,
        duration: 1,
        ease: "power4.inOut",
        stagger: 0.06,
      });
    });
  });
};

const closeMenu = () => {
  $lenis.start();

  ctx.add(() => {
    const tl = gsap.timeline();

    openSections.value.forEach((isOpen, index) => {
      if (isOpen) {
        // Réinitialiser l'état
        openSections.value[index] = false;

        // Animer la fermeture de la section
        gsap.to(linkSections.value[index], {
          height: 0,
          duration: 0.4,
          ease: "power1.inOut",
        });
      }
    });

    tl.to(el.value, {
      yPercent: -100,
      duration: 1.4,
      delay: -0.2,
      ease: "power4.inOut",
    });

    tl.to(
      name.value,
      {
        yPercent: -100,
        duration: 1,
        ease: "power4.inOut",
        stagger: 0.075,
      },
      0
    );

    tl.to(
      listItem.value,
      {
        "--width": "0%",
        duration: 1,
        delay: 0.1,
        ease: "power4.inOut",
        stagger: 0.04,
      },
      0
    );

    bottomColumn.value.forEach((column) => {
      const allSpans = column.querySelectorAll("span");

      gsap.to(allSpans, {
        yPercent: -100,
        delay: 0.45,
        duration: 1,
        ease: "power4.inOut",
        stagger: 0.06,
      });
    });
  });
};

const onLinkClick = (e) => {
  if (!e.target.classList.contains("--list")) {
    menuMobileStore.close();
  }
};

const toggleSection = (index, categories) => {
  if (categories == undefined) return;

  openSections.value[index] = !openSections.value[index];

  if (openSections.value[index]) {
    gsap.to(linkSections.value[index], {
      height: "auto",
      duration: 0.4,
      ease: "power1.inOut",
    });
  } else {
    gsap.to(linkSections.value[index], {
      height: 0,
      duration: 0.4,
      ease: "power1.inOut",
    });
  }
};
</script>

<style lang="scss" scoped>
.menu-mobile-overlay {
  position: fixed;
  inset: 0;

  overflow: auto;
  overscroll-behavior: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  z-index: 9;
  background-color: $beige;
  visibility: hidden;
  padding: 0 var(--padding-mobile) 0;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.menu-content {
  min-height: 100svh;
  padding-bottom: 2.4rem;
  padding-top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-content .menu-list {
  position: relative;
  padding-bottom: $padding-mobile-big;
}

.list-item {
  padding-top: 1.3rem;
  position: relative;
  --width: 0%;
  overflow: hidden;
  &::before {
    content: "";
    width: var(--width);
    background-color: $black;
    height: 0.5px;
    position: absolute;
    inset: 0;
  }
}

.surface {
  position: relative;
  overflow: hidden;
}

.category-name {
  @include menu-category;
  line-height: 3rem;
  display: flex;
  gap: 0.5rem;
}

.category-name--number {
  @include body-regular;
}

.links {
  margin-bottom: 0.5rem;
}

.links-container {
  @include grid-mobile($margin: 0);
  grid-auto-rows: min-content;
  row-gap: 0;
  padding: 1.5rem 0 3.5rem;
}

.links.list-5 {
  display: flex;
  flex-direction: column;
}

.link {
  @include mobile-path;
  color: rgba($color: #000000, $alpha: 0.68);
  grid-column: span 6;
  overflow: hidden;
  display: inline-block;
  span {
    display: inline-block;
  }
}

.cross {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0.1rem;
  top: 0.5rem;
}

.cross-vertical,
.cross-horizontal {
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cross-vertical {
  height: 15px;
  width: 1px;
}

.cross-horizontal {
  height: 1px;
  width: 15px;
}

.cross.open {
  .cross-vertical {
    opacity: 0;
  }
}

.link-sample {
  width: 100%;
  border: solid 1px black;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include link;
  text-transform: uppercase;
  .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 100px;
    background-color: $black;
    margin-right: 2.5px;
    transform: translateY(0.5px);
  }
}

.bottom-list {
  @include grid-mobile;
  max-width: 100%;
}

.list-column {
  grid-column: span 4;
  width: calc(100% + 1.2rem);
  display: flex;
  flex-direction: column;
}

.bottom-title,
.bottom-link {
  display: inline-block;
  overflow: hidden;
  span {
    display: inline-block;
  }
}

.bottom-title {
  @include caption-small;
  margin-bottom: 1.2rem;
}

.bottom-link {
  @include link;
  padding-right: 0.25rem;
}
</style>
