import { richTextToHTML } from "@/utils/shopify";
import axios from "axios";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";

const structureSpecifications = (data) => {
  const documents = [
    { title: "Moulding Sheet", value: data.mouldingSheetDocument?.value },
    { title: "Maintenance", value: data.maintenanceDocument?.value },
    { title: "Installation", value: data.installationDocument?.value },
    { title: "Warranty", value: data.warrantyDocument?.value },
    { title: "Specifications", value: data.specificationsDocument?.value },
  ];

  const specificationsList = [
    { title: "High Variation", value: data.highVariation?.value },
    { title: "Depth and Richness", value: data.depthAndRichness?.value },
    { title: "Finish", value: data.finish?.value },
    { title: "Patina", value: data.patina?.value },
    {
      title: "Direction for Use",
      value: richTextToHTML(data.directionsForUse?.value),
    },
  ];

  const specificationsGrid = [
    { title: "Species", value: data.species?.value },
    { title: "Grade", value: data.grade?.value },
    { title: "Construction", value: data.construction?.value },
    { title: "Finish", value: data.finish?.value },
    { title: "Thickness", value: data.thickness_string?.value },
    {
      title: "Plank Width",
      value: data.catWidth?.value
        ? JSON.parse(data.catWidth.value).join(", ")
        : undefined,
    },
    {
      title: "Plank Shade",
      value: data.catShade?.value
        ? JSON.parse(data.catShade.value).join(", ")
        : undefined,
    },
    { title: "Product Line", value: data.productLine?.value },
    // { title: "Installation Methods", value: data.installationMethods?.value },
    // { title: "Surface Treatment", value: data.surfaceTreatment?.value },
    // { title: "Top Layer", value: data.topLayer?.value },
    // { title: "Width", value: data.width_string?.value },
    // { title: "Length", value: data.length_string?.value },
    // {
    //   title: "Radiant Heating",
    //   value: richTextToHTML(data.radiantHeating?.value),
    // },
    // { title: "Coverage Box", value: richTextToHTML(data.coverageBox?.value) },
    // { title: "First Aid", value: richTextToHTML(data.firstAid?.value) },
    // { title: "Warranty", value: data.warranty?.value },
    // {
    //   title: "Specifications Notes",
    //   value: richTextToHTML(data.specificationNotes?.value),
    // },
    // {
    //   title: "Hardwood Available Trims",
    //   value: data.hardwoodAvailableTrims?.value,
    // },
  ];

  console.log({ specificationsGrid });

  return { specificationsList, specificationsGrid, documents };
};

const PRODUCT_FIELDS = `
  id
  handle
  collections(first: 5) {
    edges {
      node {
        id
        handle
        title
      }
    }
  }
  title
  description
  descriptionHtml
  productType
  images(first: 15) {
    edges {
      node {
        src
        altText
      }
    }
  }
  priceRange {
    minVariantPrice {
      amount
      currencyCode
    }
  }
  displayTitle: metafield(namespace: "custom", key: "display_title") { value }
  specs: metafield(namespace: "custom", key: "specs") { value }
  netVolumeMetric: metafield(namespace: "custom", key: "net_volume_metric") { value }
  netVolumeImperial: metafield(namespace: "custom", key: "net_volume_imperial") { value }
  coverage: metafield(namespace: "custom", key: "coverage") { value }
  radiantHeating: metafield(namespace: "custom", key: "radiant_heating") { value }
  firstAid: metafield(namespace: "custom", key: "first_aid") { value }
  directionsForUse: metafield(namespace: "custom", key: "directions_for_use") { value }
  documentation: metafield(namespace: "custom", key: "documentation") { value }
  thicknessString: metafield(namespace: "custom", key: "thickness_string") { value }
  widthString: metafield(namespace: "custom", key: "width_string") { value }
  lengthString: metafield(namespace: "custom", key: "length_string") { value }
  topLayer: metafield(namespace: "custom", key: "top_layer") { value }
  coverageBox: metafield(namespace: "custom", key: "coverage_box") { value }
  warranty: metafield(namespace: "custom", key: "warranty") { value }
  surfaceTreatment: metafield(namespace: "custom", key: "surface_treatment") { value }
  installationMethods: metafield(namespace: "custom", key: "installation_methods") { value }
  finish: metafield(namespace: "custom", key: "finish") { value }
  highVariation: metafield(namespace: "custom", key: "high_variation") { value }
  depthAndRichness: metafield(namespace: "custom", key: "depth_and_richness") { value }
  patina: metafield(namespace: "custom", key: "patina") { value }
  construction: metafield(namespace: "custom", key: "construction") { value }
  grade: metafield(namespace: "custom", key: "grade") { value }
  species: metafield(namespace: "custom", key: "species") { value }
  mouldingSheetDocument: metafield(namespace: "custom", key: "moulding_sheet_document") { value }
  maintenanceDocument: metafield(namespace: "custom", key: "maintenance_document") { value }
  installationDocument: metafield(namespace: "custom", key: "installation_document") { value }
  warrantyDocument: metafield(namespace: "custom", key: "warranty_document") { value }
  specificationNotes: metafield(namespace: "custom", key: "specification_notes") { value }
  vimeoId1: metafield(namespace: "custom", key: "vimeo_id_1") { value }
  vimeoId2: metafield(namespace: "custom", key: "vimeo_id_2") { value }
  catSpecies: metafield(namespace: "custom", key: "wood_species") { value }
  catFinish: metafield(namespace: "custom", key: "flooring_finish") { value }
  catWidth: metafield(namespace: "custom", key: "plank_width") { value }
  catShade: metafield(namespace: "custom", key: "plank_shade") { value }
  style: metafield(namespace: "custom", key: "style_list") { value }
  application: metafield(namespace: "custom", key: "application_list") { value } 
  flexibility: metafield(namespace: "custom", key: "flexibility_list") { value }
  productLine: metafield(namespace: "custom", key: "product_line") { value }
  specificationsDocument: metafield(namespace: "custom", key: "specifications_document_flat_panel") { value }
  notForSample: metafield(namespace: "custom", key: "not_for_sample") { value }
  variants(first: 100) {
    edges {
      node {
        id
        title
        availableForSale
        selectedOptions {
          name
          value
        }
        price {
          amount
          currencyCode
        }
      }
    }
  }
  options {
    name
    values
  }
`;

export const useProductStore = defineStore("product", {
  state: () => {
    const config = useRuntimeConfig();
    return {
      products: [],
      careProducts: [],
      singleProduct: null,
      collectionProducts: [],
      errors: null,
      SHOPIFY_STORE_URL: config.public.SHOPIFY_STORE_URL,
      STOREFRONT_ACCESS_TOKEN: config.public.SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    };
  },

  actions: {
    // Helper function to fetch the URL for each document by GID
    async downloadDocument(gid) {
      const query = `
        query($id: ID!) {
          node(id: $id) {
            ... on GenericFile {
              url
            }
          }
        }
      `;

      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          { query, variables: { id: gid } },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const fileUrl = response.data?.data?.node?.url;

        if (fileUrl) {
          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = fileUrl;
          anchor.target = "_blank";
          anchor.download = fileUrl.split("/").pop(); // Set filename from URL or customize as needed
          document.body.appendChild(anchor); // Append to the DOM

          anchor.click(); // Trigger download
          document.body.removeChild(anchor); // Remove from the DOM
        } else {
          console.error("File URL not found");
        }
      } catch (error) {
        console.error(`Error fetching document URL for ${gid}:`, error);
      }
    },

    async structureSpecifications(data) {
      const documents = await Promise.all([
        { title: "Moulding Sheet", gid: data.mouldingSheetDocument?.value },
        { title: "Maintenance", gid: data.maintenanceDocument?.value },
        { title: "Installation", gid: data.installationDocument?.value },
        { title: "Warranty", gid: data.warrantyDocument?.value },
        { title: "Specifications", gid: data.specificationsDocument?.value },
      ]);

      const specificationsList = [
        { title: "High Variation", value: data.high_variation?.value },
        { title: "Depth and Richness", value: data.depth_and_richness?.value },
        { title: "Finish", value: data.finish?.value },
        { title: "Patina", value: data.patina?.value },
        {
          title: "Direction for Use",
          value: richTextToHTML(data.directionsForUse?.value),
        },
      ];

      const specificationsGrid = [
        { title: "Species", value: data.species?.value },
        { title: "Grade", value: data.grade?.value },
        { title: "Construction", value: data.construction?.value },
        { title: "Finish", value: data.finish?.value },
        { title: "Thickness", value: data.thickness_string?.value },
        {
          title: "Installation Methods",
          value: data.installationMethods?.value,
        },
        { title: "Surface Treatment", value: data.surfaceTreatment?.value },
        { title: "Top Layer", value: data.topLayer?.value },
        { title: "Width", value: data.width_string?.value },
        { title: "Length", value: data.length_string?.value },
        {
          title: "Radiant Heating",
          value: richTextToHTML(data.radiantHeating?.value),
        },
        {
          title: "Coverage Box",
          value: richTextToHTML(data.coverageBox?.value),
        },
        { title: "First Aid", value: richTextToHTML(data.firstAid?.value) },
        { title: "Warranty", value: data.warranty?.value },
        {
          title: "Specifications Notes",
          value: richTextToHTML(data.specificationNotes?.value),
        },
        {
          title: "Hardwood Available Trims",
          value: data.hardwoodAvailableTrims?.value,
        },
      ];

      return { specificationsList, specificationsGrid, documents };
    },
    async fetchProductsByQuery(query, targetArray) {
      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          { query },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;
        if (data && data.products) {
          this[targetArray] = data.products.edges.map((edge) => edge.node);
          const products = this[targetArray].map((product) => {
            const { specificationsList, specificationsGrid, documents } =
              structureSpecifications(product);
            return {
              ...product,
              specificationsList,
              specificationsGrid,
              documents,
            };
          });
          return { success: true, products };
        } else {
          throw new Error("Failed to fetch products");
        }
      } catch (error) {
        console.error("Error fetching products", error);
        this.errors = [
          { message: "Something went wrong while fetching products." },
        ];
        return { success: false, errors: this.errors };
      }
    },

    async fetchCareProducts({ limit = 25 } = {}) {
      const query = `
        {
          products(first: ${limit}, query: "product_type:care OR product_type:maintenance") {
            edges { node { ${PRODUCT_FIELDS} } }
          }
        }
      `;
      return await this.fetchProductsByQuery(query, "careProducts");
    },

    async fetchProducts({ limit = 250 } = {}) {
      const query = `
        {
          products(first: ${limit}, query: "NOT product_type:care AND NOT product_type:maintenance") {
            edges { node { ${PRODUCT_FIELDS} } }
          }
        }
      `;
      return await this.fetchProductsByQuery(query, "products");
    },

    async fetchProductsByType(productType, { limit = 250 } = {}) {
      const query = `
        query getProductsByType($productType: String!) {
          products(first: ${limit}, query: $productType) {
            edges {
              node {
                ${PRODUCT_FIELDS}
              }
            }
          }
        }
      `;

      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          {
            query,
            variables: {
              productType: `product_type:${productType}`,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;

        if (data && data.products) {
          const products = data.products.edges.map((edge) => {
            const product = edge.node;
            const { specificationsList, specificationsGrid, documents } =
              structureSpecifications(product);
            return {
              ...product,
              specificationsList,
              specificationsGrid,
              documents,
            };
          });
          return { success: true, products };
        } else {
          throw new Error("Failed to fetch products by type");
        }
      } catch (error) {
        console.error("Error fetching products by type", error);
        this.errors = [
          {
            message: "Something went wrong while fetching products by type.",
          },
        ];
        return { success: false, errors: this.errors };
      }
    },

    async fetchProductsByCollection(handle) {
      const query = `
        query getCollectionByHandle($handle: String!) {
          collectionByHandle(handle: $handle) {
            products(first: 250) {
              edges {
                node {
                  ${PRODUCT_FIELDS}
                }
              }
            }
          }
        }
      `;

      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          { query, variables: { handle } },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;

        if (
          data &&
          data.collectionByHandle &&
          data.collectionByHandle.products
        ) {
          this.collectionProducts = data.collectionByHandle.products.edges.map(
            (edge) => edge.node
          );
          const products = this.collectionProducts.map((product) => {
            const { specificationsList, specificationsGrid, documents } =
              structureSpecifications(product);
            return {
              ...product,
              specificationsList,
              specificationsGrid,
              documents,
            };
          });
          return { success: true, products };
        } else {
          throw new Error("Failed to fetch collection products");
        }
      } catch (error) {
        console.error("Error fetching collection products", error);
        this.errors = [
          {
            message: "Something went wrong while fetching collection products.",
          },
        ];
        return { success: false, errors: this.errors };
      }
    },

    async fetchProductById(productId) {
      return await this.fetchProductDetail(`product(id: "${productId}")`);
    },

    async fetchProductByHandle(handle) {
      return await this.fetchProductDetail(
        `productByHandle(handle: "${handle}")`
      );
    },

    async fetchProductsByHandles(handles) {
      const queries = handles
        .map(
          (handle, index) => `
            product${index}: productByHandle(handle: "${handle}") {
              ${PRODUCT_FIELDS}
            }
          `
        )
        .join("\n");

      const query = `
        query {
          ${queries}
        }
      `;

      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          { query },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;
        if (data) {
          // Map over each product response and structure specifications
          const products = handles
            .map((handle, index) => {
              const product = data[`product${index}`];
              if (product) {
                const { specificationsList, specificationsGrid, documents } =
                  structureSpecifications(product);
                return {
                  ...product,
                  specificationsList,
                  specificationsGrid,
                  documents,
                };
              }
              return null;
            })
            .filter(Boolean); // Remove any null entries for products not found

          return { success: true, products };
        } else {
          throw new Error("Failed to fetch products by handles");
        }
      } catch (error) {
        console.error("Error fetching products by handles", error);
        this.errors = [
          {
            message: "Something went wrong while fetching products by handles.",
          },
        ];
        return { success: false, errors: this.errors };
      }
    },

    async fetchProductDetail(nodeQuery) {
      const router = useRouter(); // Access the router

      const query = `
        query {
          ${nodeQuery} { ${PRODUCT_FIELDS} }
        }
      `;

      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          { query },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;
        const product = data?.product || data?.productByHandle;
        if (product) {
          this.singleProduct = product;
          const { specificationsList, specificationsGrid, documents } =
            structureSpecifications(product);
          const output = {
            ...this.singleProduct,
            specificationsList,
            specificationsGrid,
            documents,
          };
          return { success: true, product: output };
        } else {
          // Redirect to the 404 page
          router.push("/404");
          throw new Error("Failed to fetch product");
        }
      } catch (error) {
        console.error("Error fetching product details", error);
        this.errors = [
          { message: "Something went wrong while fetching the product." },
        ];
        return { success: false, errors: this.errors };
      }
    },

    // Add this action to your `useProductStore`
    async searchProducts(searchQuery, limit = 5) {
      const query = `
        query searchProducts($searchQuery: String!, $limit: Int!) {
          products(first: $limit, query: $searchQuery) {
            edges {
              node {
                ${PRODUCT_FIELDS}
              }
            }
          }
        }
      `;

      try {
        const response = await axios.post(
          `https://${this.SHOPIFY_STORE_URL}/api/2023-04/graphql.json`,
          {
            query,
            variables: {
              searchQuery,
              limit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": this.STOREFRONT_ACCESS_TOKEN,
            },
          }
        );

        const { data } = response.data;

        if (data && data.products) {
          const products = data.products.edges.map((edge) => {
            const product = edge.node;
            const { specificationsList, specificationsGrid, documents } =
              structureSpecifications(product);
            return {
              ...product,
              specificationsList,
              specificationsGrid,
              documents,
            };
          });
          return { success: true, products };
        } else {
          throw new Error("No products found");
        }
      } catch (error) {
        console.error("Error searching products", error);
        this.errors = [
          { message: "Something went wrong while searching products." },
        ];
        return { success: false, errors: this.errors };
      }
    },
  },
});
